import React, { Component } from 'react';

// Routing
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
  Link
} from 'react-router-dom'

// Styles
import { TransitionGroup, CSSTransition } from "react-transition-group";
import GlobalStyle from "./styles/global";

// Containers
import IndexContainer from './containers/IndexContainer';
import AboutContainer from "./containers/AboutContainer";
import ProjectsContainer from "./containers/ProjectsContainer";
import ProjectContainer from "./containers/ProjectContainer";
import StudioContainer from "./containers/StudioContainer";
import PolicyContainer from "./containers/PolicyContainer";
import TermsContainer from "./containers/TermsContainer";

// Views
import NotFound from "./views/NotFound";

// Components
import Menu from './components/Menu';
import ScrollWatch from './components/ScrollWatch'

// Utils
import { setScroll, resetScroll } from './utils/scroll'

// Redux
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, compose } from 'redux';
import rootReducer from './state/reducers';
import initialState from './state/initialState'
import * as actions from './state/actions/actions'

import thunkMiddleware from 'redux-thunk';
import promiseMiddleware from 'redux-promise-middleware';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const middlewares = [thunkMiddleware, promiseMiddleware()];

const store = createStore(
    rootReducer,
    initialState,
    composeEnhancers(applyMiddleware(...middlewares)),
);

// initial dispatch (could do this elsewhere)
store.dispatch(actions.getData())
store.dispatch(actions.checkDeviceWidth(window.innerWidth))

let resizeTimer
window.addEventListener('resize', () => {
  clearTimeout(resizeTimer);
  resizeTimer = setTimeout(() => {
    let ww = window.innerWidth;
    store.dispatch(actions.checkDeviceWidth(ww));
  }, 250);
});


class App extends Component {

  returnTransition(path) {
   if (path.includes('/photography/')) {
     return false 
   }
   else {
     return 'fade'
   }
  }

  returnKey(path, k) {
    return path.includes('/photography/') || path === '/photography' ? 'photography' : k
  }

  handleEnter(loc) {
  }

  handleExit(loc) {
  }

  // gave TransitionGroup class name of 'page' so it can have relative style propery
  // (this prevents the animation from jumping)
  render() {
    const self = this
    return (
      <Provider store={store}>
      <Router>
        <ScrollWatch>
          <GlobalStyle />
          <Menu location={location} />
          <Route render={({ location }) => {
            const { pathname, key } = location
            return (
              <TransitionGroup className='page' component={null}>
                <CSSTransition
                  key={self.returnKey(pathname, key)}
                  // enter delay must be included in enter timeout
                  timeout={{enter: 750, exit: 250}}
                  classNames={self.returnTransition(pathname)}
                  onEntered={() => self.handleEnter(pathname)}
                  onExited={() => self.handleExit(pathname)}
                >
                  <Switch location={location}>
                    <Route name="about" exact path={`${process.env.PUBLIC_URL}/about`} history={history} component={AboutContainer} />
                    <Route name="home" exact path={`${process.env.PUBLIC_URL}/`} history={history} component={IndexContainer} location={location} />
                    <Route name="photography" path={`${process.env.PUBLIC_URL}/photography`} history={history} component={ProjectsContainer} location={location} />
                    <Route name="autonomous" path={`${process.env.PUBLIC_URL}/autonomous`} history={history} component={StudioContainer} location={location} />
                    <Route name="privacy-policy" exact path={`${process.env.PUBLIC_URL}/privacy-policy`} component={PolicyContainer} location={location} />
                    <Route name="agb" exact path={`${process.env.PUBLIC_URL}/agb`} component={TermsContainer} location={location} />
                  </Switch>
                </CSSTransition>
              </TransitionGroup>
            )
          }}/>
         </ScrollWatch>
      </Router>
      </Provider>
    )
  }
}

export default App