import React, { Component } from "react";
import ReactPlayer from "react-player";
import { CSSTransition, TransitionGroup } from "react-transition-group"
import Markup from "interweave";

import {
  Slide,
  SlideInner,
  CaptionWrap,
  CaptionWrapLandscapeXS
} from "../styles/slide";


class SlideWrapper extends Component {
  constructor(props) {
    super(props);
    const self = this;
    this.state = {
      visible: this.props.visible,
      leftCursor: false,
      count: 0,
      imagesLoaded: false
    };
  }

  componentDidMount() {
    this.setState({
      count: this.props.imageIndex
    })

    this.loadSlides()
    if (this.props.slideshow[this.props.imageIndex].type === 'video') {
      this.muteVideo()
    }
  }

  loadSlides() {
    const self = this
    this.props.slideshow.map((i, key) => {
      if (i.type === "image") {
        const img = new Image();
        img.src = i.url;
        img.onload = () => {
          // setTimeout(() => {
          //   if (!self.state.transition) {
          //     self.setState({
          //       transition: true
          //     })
          //   }
          // }, 500)
        };
      }
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.slug !== this.props.slug) {
      this.loadSlides();
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.imageIndex !== this.props.imageIndex) {
      this.setState({
        count: nextProps.imageIndex
      });
      // mute video on slide
      if (nextProps.slideshow[nextProps.imageIndex].type === 'video') {
        this.muteVideo()
      }
    }
    if (nextProps.visible !== this.props.visible) {
      this.setState({
        visible: nextProps.visible
      });
    }
  }

  muteVideo() {
    const self = this 
    setTimeout(() => {
      self.refs.video.setAttribute('muted', '1')
    }, 250)
  }

  handleMouseMove(event) {
    var mouseX = event.pageX
    var ww = window.innerWidth
    var cursorCondition = mouseX > ww / 2 ? true : false 
    this.setState({
      leftCursor: cursorCondition
    });
  }

  render() {
    if (!this.props.slideshow[this.state.count]) {
      return false
    } else {
      var currentSlide = this.props.slideshow[this.state.count];
      var mobile = this.props.mobile
      return (
        <TransitionGroup id="transitionWrap">
          <CSSTransition
            key={this.props.slideshow[this.state.count].key}
            timeout={500}
            classNames="fade-slideshow"
          >
            <Slide cursorState={this.state.leftCursor} onMouseMove={this.handleMouseMove.bind(this)}>
              <SlideInner
                transition={this.props.transition}
                visible={this.state.visible}
                ratio={currentSlide.ratio}
                hasCaption={currentSlide.caption.length ? true : false}
              >
                {currentSlide.type === "image" ? (
                  <img src={currentSlide.url} />
                ) : 
                currentSlide.type === "video" ? (
                  <video ref = 'video' loop='loop' muted='muted' autoplay='autoplay' playsinline='playsinline' disableRemotePlayback='disableRemotePlayback' src={currentSlide.url}></video>
                )
                :
                (
                  <div className="player-wrapper">
                    <div className="player-wrapper-inner">
                      <ReactPlayer
                        className="react-player"
                        url={currentSlide.url}
                      />
                    </div>
                    {currentSlide.caption.length && !mobile &&  window.innerWidth > window.innerHeight ? (
                      <Markup tagName='figcaption' content={currentSlide.caption} />
                    ) : (
                      false
                    )}
                  </div>
                )}
                {/* video */}
                {mobile || window.innerWidth < window.innerHeight ?
                  false
                  :
                  currentSlide.caption.length && currentSlide.type !== 'embed' ?
                    (

                      <CaptionWrap id="captionWrap">
                        <Markup tagName='figcaption' content={currentSlide.caption} />
                      </CaptionWrap>
                    )
                  : 
                  false
                }
              </SlideInner>
              {/* mobile caption for landscape images + video */}
              {
                mobile || window.innerWidth < window.innerHeight ?
                  currentSlide.caption.length && currentSlide.type === 'image' ?
                    (
                      <CaptionWrapLandscapeXS visible={this.state.visible}>
                        <Markup tagName='figcaption' content={currentSlide.caption} />
                      </CaptionWrapLandscapeXS>
                    )
                    :
                    currentSlide.caption.length && currentSlide.type === 'video' ?
                    (
                      <CaptionWrapLandscapeXS visible={this.state.visible}>
                        <Markup tagName='figcaption' content={currentSlide.caption} />
                      </CaptionWrapLandscapeXS>
                    )
                    :
                    false
                    :
                  false
                }
            </Slide>
          </CSSTransition>
        </TransitionGroup>
      );
    }
  }
}

export default SlideWrapper