import React, { Component } from "react";
import IndexView from "../views/IndexView";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as actions from "../state/actions/actions";
import { withRouter } from "react-router-dom"

const mapStateToProps = state => {
  return {
    mobile: state.mobile,
    data: state.data,
    breakpointXL: state.breakpointXL,
    breakpointXXL: state.breakpointXXL
  };
};

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(
      actions,
      dispatch
    )
  };
};
  
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(IndexView);