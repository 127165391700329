import styled, { keyframes, css } from "styled-components";

export const bpSizes = {
  a: 480,
  b: 768,
  c: 1290,
  d: 1440,
  e: 1600
};

const screens = {
  a: bpSizes.a + 'px',
  b: bpSizes.b + 'px',
  c: bpSizes.c + 'px',
  d: bpSizes.d + 'px',
  e: bpSizes.e + 'px',
};

export const breakpoint = {
  xs: `(min-width: 0) and (max-width: ${screens.a})`,
  landscape: `(max-width: ${screens.b}) and (orientation: landscape)`,
  s: `(min-width: 481px) and (max-width: ${screens.b})`,
  m: `(min-width: 769px) and (max-width: ${screens.c})`,
  l: `(min-width: 1281px) and (max-width: ${screens.d})`,
  xl: `(min-width: 1441px) and (max-width: ${screens.e})`,
  xxl: `(min-width: 1601px)`
};

export const menuHeights = {
  xs: '65px',
  s: '50px',
  m: '55px',
  l: '60px',
  xl: '65px',
  xxl: '75px' 
}

export const pageMargin = '10px'
export const topBarHeight = '0.9em'
export const grey = '#d9d9d9'

export const fadeIn = () => {
  return keyframes`
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  `;
};