import React, { Component } from "react";
import StudioView from "../views/StudioView";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as actions from "../state/actions/actions";
import { withRouter } from "react-router-dom"

const mapStateToProps = state => {
  return {
    data: state.data,
    projectPage: state.projectPage,
    studioFilter: state.studioFilter,
    filter: state.filter,
    loadedThumbs: state.loadedThumbs,
    loaderHidden: state.loaderHidden,
    mobile: state.mobile,
    showThumbs: state.showThumbs,
    breakpointXL: state.breakpointXL,
    breakpointXXL: state.breakpointXXL,
    isProject: state.isProject,
    scrollPos: state.scrollPos
  };
};

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(
      actions,
      dispatch
    )
  };
};

class StudioContainer extends Component {
  constructor(props) {
    super(props)
  }

  componentDidMount() {
    this.props.actions.setStudio()
  }

  render() {
    return <StudioView {...this.props} />
  }
}
  
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StudioContainer);