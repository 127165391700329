import styled, { css } from 'styled-components'
import { breakpoint, grey } from "./utils";


export const typeGeneral = css`
  font-family: Gaisyr, Helvetica, Arial, sans-serif;
  font-size: 1rem;
  line-height: 1;
  letter-spacing: 0;
`;

export const typeBody = css`
  font-family: Times New Roman;
  font-size: .95rem;
  line-height: 1.1;
  letter-spacing: 0;
`;

export const typeBodyTitle = css`
  font-family: Gaisyr, Helvetica, Arial, sans-serif;
  font-size: 1.4rem;
  line-height: 1;
  letter-spacing: 0;
  @media ${breakpoint.xs} { 
    font-size: 1.6rem;
  }
`;

export const typeCaptions = css`
  font-family: Gaisyr, Helvetica, Arial, sans-serif;
  font-size: 0.67rem;
  line-height: 1;
  letter-spacing: 0;
  & a {
    color: ${grey};
    text-decoration: none;
    transition: color 0.25s ease;
    @media (hover: hover) {
      &:hover {
        color: black;
      }
    }
  }
  @media ${breakpoint.xs} { 
    font-size: 10px;
  }
  @media ${breakpoint.s} { 
    font-size: 10px;
  }
`;

export const typeLarge = css`
  font-family: Gaisyr, Helvetica, Arial, sans-serif;
  font-size: 1.4rem;
  line-height: 1;
  letter-spacing: 0;
  @media ${breakpoint.xs} { 
    font-size: 24px;
  }
`;

export const typeMedium = css`
  font-family: Gaisyr, Helvetica, Arial, sans-serif;
  font-size: .825rem;
  line-height: 1;
  letter-spacing: 0;
`;

export const typeSmall = css`
  font-family: Gaisyr, Helvetica, Arial, sans-serif;
  font-size: 10px;
  line-height: 1;
  letter-spacing: 0;
`;