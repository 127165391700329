import React, { Component } from "react";
import styled from "styled-components";
import { typeSmall, typeGeneral, typeBody, typeBodyTitle, typeMedium, typeLarge, typeCaptions } from "../../styles/typography";
import { breakpoint, pageMargin } from "../../styles/utils";
import Markup from "interweave";

const StudioMenuWrapper = styled.ul`
  background: white;
  display: flex;
  flex-flow: column;
  position: fixed;
  width: calc(100% - calc(${pageMargin} * 2));
  top: 0;
  z-index: 3;
  transition: opacity .15s linear;
  opacity: ${props => (props.showMenu ? `1` : `0`)};
  pointer-events: ${props => (props.showMenu ? `all` : `none`)};
`;

const StudioEntryTitle = styled.li`
  order: ${props => (props.isActive ? `1` : props.order)};
  display: ${props => (props.isActive ? `flex` : props.menuOpen ? `flex` : `none`)};
  border: solid black 1px;
  border-top: 0;
  border-right: 0;
  border-left: 0;
  padding: ${pageMargin} 0 ${pageMargin} 0;
  cursor: ${props => (props.entriesLength > 1 ? `pointer` : `default`)};
  align-items: center;
  user-select: none;
  & span {
    margin-right: .5rem;
    transition: opacity .2s linear;
  }
  & img {
    margin-top: -2px;
    height: .75em;
    transition: transform .25s linear, opacity .2s linear;
    transform: ${props => (props.menuOpen ? `rotate(0)` : `rotate(180deg)`)};
  }
  @media (hover: hover) {
    &:hover {
      & span {
        opacity: ${props => (props.entriesLength > 1 ? `.3` : `1`)};
      }
      & img {
        opacity: ${props => (props.entriesLength > 1 ? `.3` : `1`)};
      }
    }
  }
`;


class StudioMenu extends Component {
  constructor(props) {
    super(props)
    this.state = {
      menuOpen: false
    }
  }

  handleClick(str, len) {
    if (len <= 1) {
      return false
    }
    else {
      if (str === this.props.currentSlug) {
        this.state.menuOpen ? this.setState({ menuOpen: false }) : this.setState({ menuOpen: true })
      }
      else {
        let el = document.getElementById(str) 
        el.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"})
        this.setState({
          menuOpen: false
        })
      }
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.showMenu !== this.props.showMenu) {
      if (!this.props.showMenu) {
        this.setState({
          menuOpen: false
        })
      }
    }
  }

  returnOrder(n) {
    return n + 1
  }

  render() {
    const self = this
    const entries = this.props.studioFilter === 'All' ? this.props.data : this.props.data.filter((entry, key) => {
      let tags = []
      entry.layout.map((x, key) => {
        x.tags.map((tag, key) => {
          tags.push(tag)
        })
      })
      return tags.includes(self.props.studioFilter) ? entry : false
    })

    const entriesLength = entries.length

    return (
      <StudioMenuWrapper showMenu={this.props.showMenu}>
        {
          entries.map((post, i) => {
            const isVisible = self.props.currentSlug === post.slug
            return (
              <StudioEntryTitle 
                entriesLength={entriesLength}
                isActive={isVisible} 
                menuOpen={self.state.menuOpen}
                order={this.returnOrder(parseInt(post.id))} 
                onClick={self.handleClick.bind(self, post.slug, entriesLength)}
              >
                <span>{post.title}, {post.year}</span>
                {isVisible ? <img src={process.env.PUBLIC_URL + "/up-arrow.svg"} /> : false}
              </StudioEntryTitle>
            );
          })
        }
      </StudioMenuWrapper>
    )
  }
}

export default StudioMenu;
