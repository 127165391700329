import React, { Component } from "react";
import AboutView from "../views/AboutView";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as actions from "../state/actions/actions";
import { withRouter } from "react-router-dom";

const mapStateToProps = state => {
  return {
    data: state.data
  };
};

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(
      actions,
      dispatch
    )
  };
};

class AboutContainer extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    return (
      <AboutView {...this.props} />
    )
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AboutContainer)
