import React, { Component } from "react";
import styled, { keyframes } from "styled-components";
import { typeSmall, typeGeneral, typeBody, typeBodyTitle, typeMedium, typeLarge, typeCaptions } from "../../styles/typography";
import { breakpoint, menuHeights, pageMargin, fadeIn } from "../../styles/utils";
import Markup from "interweave";
import StudioFigure from './StudioFigure'

const DoubleImageEl = styled.div`
  grid-column-start: 1;
  grid-column-end: 7;
  align-items: center;
  display: flex;
  margin-bottom: ${props => (props.mobile ? `4rem` : `6rem`)};
  flex-flow: ${props => (props.mobile ? `column` : `auto`)};;
`;

const DoubleImageColumn = styled.div`
  width: ${props => (props.mobile ? `100%` : `calc(50% - (${pageMargin} / 2))`)};
  &.lazyload-wrapper {
    width: 100%;
  }
  &:first-child {
    margin-right: ${props => (props.mobile ? `0` : `calc(${pageMargin} / 2)`)};
    margin-bottom: ${props => (props.mobile ? `4rem` : `0`)};
  }
  &:last-child {
    margin-left: ${props => (props.mobile ? `0` : `calc(${pageMargin} / 2)`)};
    margin-bottom: 0;
  }
`

const DoubleImage = (props) => {
  let captionOne = props.data.imageone.caption.length > 0 ? <figcaption><Markup content={props.data.imageone.caption}/></figcaption> : false
  let captionTwo = props.data.imagetwo.caption.length > 0 ? <figcaption><Markup content={props.data.imagetwo.caption}/></figcaption>  : false
  let imgOneWidth = props.mobile ? window.innerWidth : props.data.imageone.ratio < 1 ? ((window.innerWidth / 2) * .6) : (window.innerWidth / 2)
  let imgTwoWidth = props.mobile ? window.innerWidth : props.data.imagetwo.ratio < 1 ? ((window.innerWidth / 2) * .6) : (window.innerWidth / 2)

  return (
    <DoubleImageEl mobile={props.mobile}>
      <DoubleImageColumn mobile={props.mobile}>
        <StudioFigure isSingle={false} width={imgOneWidth} ratio={props.data.imageone.ratio} src={props.data.imageone.thumbnails.l} caption={captionOne} />
      </DoubleImageColumn>
      <DoubleImageColumn mobile={props.mobile}>
        <StudioFigure isSingle={false} width={imgTwoWidth} ratio={props.data.imagetwo.ratio} src={props.data.imagetwo.thumbnails.l} caption={captionTwo} />
      </DoubleImageColumn>
    </DoubleImageEl>
  );
}

export default DoubleImage