import React, { Component } from 'react';
import Wrapper from "../components/Wrapper";
import Menu from "../components/Menu";
import { Markup, FilterInterface } from "interweave";
import Loader from '../components/Loader'
import { Link } from 'react-router-dom'

import {
  PageTitle,
  TextBlock,
  Grid,
  Policy,
  Col,
  PolicyLinks,
  InfoList
} from "../styles/about";



function splitList(str) {
  let list = str.split('<p>');
  let chars = {'</p>':'',' -- ':'&nbsp;'};

  let arr = list.map((i, key) => {
    return i.replace('</p>', '')
  })

  let text = arr.map((li, key) => {
    if (key >= 1) {
      let splitText = li.split('--')
      let content = splitText[0].includes('–') ? '<span class = "year double">' + splitText[0] + '</span>' + '<span class = "text">' + splitText[1] + '</span>' : '<span class = "year single">' + splitText[0] + '</span>' + '<span class = "text">' + splitText[1] + '</span>'
      return <Markup tagName='li' content={content} />
    }
  })
  return text 
}

const AboutView = ({ location, ...props }) => {
  if (props.data.length === 0) {
    return <Loader hideLoader={false} />
  }
  else {
    const data = props.data.about 
    const blockEntries = data.entries.map((x, key) => {
      let lastChild = key === (data.entries.length - 1) ? true : false
      if (x.type === 'about-list') {
        return (
          <TextBlock list={true} cols={false} lastChild={lastChild} noMargin={true}>
            <h1>{x.data.title}</h1>
            <InfoList>
              {splitList(x.data.text)}
            </InfoList>
          </TextBlock>
        )
      }
      else {
        return (
          <TextBlock list={false} cols={false} lastChild={lastChild} noMargin={false}>
            <h1>{x.data.title}</h1>
            <div>
              <Markup content={x.data.text} />
            </div>
          </TextBlock>
        )
      }
    })
    
    return (
      <Wrapper location={location} isIndex={false} isProject={false}>
        <PageTitle>{data.title}</PageTitle>
        <Grid>
          <Col>
            <TextBlock cols={false} lastChild={false} noMargin={false}>
              <Markup content={data.about} />
            </TextBlock>
            <TextBlock cols={false} lastChild={false} noMargin={true} >
              <Markup content={data.contact} />
              <PolicyLinks><Link to = '/agb' target={"_blank"}>General Terms & Conditions</Link>, <Link to = '/privacy-policy'>Privacy Policy</Link></PolicyLinks>
            </TextBlock>
            <TextBlock cols={false} lastChild={true} noMargin={false}>
              <Markup content={data.credits} />
            </TextBlock>
          </Col>
          <Col>
            {blockEntries}
          </Col>
        </Grid>
      </Wrapper>
    )
  }

}

export default AboutView