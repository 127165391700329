import React, { Component, useRef, useState } from 'react';
import { Link } from "react-router-dom";
import { Route, Switch } from "react-router-dom";
import ProjectContainer from '../containers/ProjectContainer'
import Wrapper from '../components/Wrapper'
import Loader from '../components/Loader'
import Thumbnail from '../components/Thumbnail'
import TextImage from '../components/studio/TextImage'
import ImageText from '../components/studio/ImageText'
import SingleImage from '../components/studio/SingleImage'
import SingleText from '../components/studio/SingleText'
import DoubleImage from '../components/studio/DoubleImage'
import StudioMenu from '../components/studio/StudioMenu'
import StudioEntry from '../components/studio/StudioEntry'
import { resetScroll, setScroll } from '../utils/scroll'
import styled from "styled-components";
import qs from 'qs'
import { pageMargin, breakpoint } from '../styles/utils'
import { typeGeneral } from '../styles/typography'
import { Waypoint } from 'react-waypoint';
import smoothscroll from 'smoothscroll-polyfill';
import { useInView } from 'react-intersection-observer';

const StudioWrapper = styled.div`
  width: 100%;
  padding-top: 8.5rem;
  position: relative;
  max-width: 1400px;
  align-self: center;
  transition: opacity .25s linear;
  opacity: ${props => (props.showStudioWrapper ? "1" : "0")};  
`;

const EntryWithRef = React.forwardRef((props, ref) => {
  return <StudioEntry innerRef={ref} {...props} />
})

class StudioView extends Component {
  constructor(props) {
    super(props)
    this.state = {
      currentSlug: '',
      showMenu: false,
      showStudioWrapper: true,
      lastScrollTop: 0,
      scrollingDown: true,
      currentId: 0
    }
    this.wrapperRef = React.createRef();
    this.handleScroll = () => {
      // handle scroll
      let st = window.pageYOffset || document.documentElement.scrollTop
      if (st > this.state.lastScrollTop) {
        // scrolling down
        if (!this.state.scrollingDown) {
          this.setState({
            scrollingDown: true
          })
        }
      } 
      else {
        // scrolling up
        if (this.state.scrollingDown) {
          this.setState({
            scrollingDown: false
          })
        }
      }
      this.setState({
        lastScrollTop: st <= 0 ? 0 : st
      })
    }
  }

  componentDidMount() {
    const self = this
    smoothscroll.polyfill()

    if (this.props.scrollPos > 1) {
      this.setState({
        showMenu: true
      })
    }

    document.body.addEventListener("click", function(event) {
      if (event.target && event.target.nodeName == "A") {
        let link = event.target.getAttribute('href')
        if (link.includes('/photography/')) {
          event.preventDefault()

          // update local state so studio wrapper can fade out
          self.setState({
            showStudioWrapper: false,
            showMenu: false
          })

          // after duration of transition (.25s), navigate route
          setTimeout(() => {
            let projectSlug = link.substring(link.indexOf('/photography/') + 1)
            self.props.history.push('/' + projectSlug)
          }, 250)

        }
      }
    })

    window.addEventListener('scroll', this.handleScroll)

  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.studioFilter !== this.props.studioFilter) {
      this.setState({ showMenu: false })
      // reset scroll to start
      resetScroll()
      // find the top most element
      const self = this
      // after window has scrolled to top, grab element
      setTimeout(() => {
        let wrapper = self.wrapperRef.current
        let closestSection = wrapper.firstChild
        let currentEntry = self.props.data.studio.entries.filter((entry, key) => {
          return entry.slug === closestSection.getAttribute('id')
        })
        self.setState({
          currentSlug: currentEntry[0].slug,
        })
      }, 500)
    }
  }

  handleOnEnter(str, id) {
    this.state.currentSlug !== str ? this.setState({ currentSlug: str }) : false
    this.state.currentId !== id ? this.setState({ currentId: id }) : false
  }

  handleOnLeave(str) {
    const posts = this.props.data.studio.entries 
    const entries = posts
    let newId = parseInt(this.state.currentId)
    if (this.state.scrollingDown) {
      this.setState({ currentSlug: entries[newId - 1].slug }) 
    }
    else {
      this.setState({ currentSlug: entries[newId - 1].slug }) 
    }
  } 

  handleWaypointEnter() {
    this.state.showMenu ? this.setState({ showMenu: false }) : false
  }

  handleWaypointLeave() {
    !this.state.showMenu ? this.setState({ showMenu: true }) : false
  }

  render() {
    if (this.props.data.length === 0) {
      return <Loader hideLoader={false} />
    }
    else {
      const self = this
      const posts = this.props.data.studio.entries 
      const excerptLength = this.props.data.studio.excerptlength
      const entries = posts
      
      const showMenu = this.props.isProject ? false : this.state.showMenu 
      const showStudioWrapper = this.props.isProject || !this.state.showStudioWrapper ? false : true
      var offset = window.innerHeight
      return (
        <React.Fragment>
          <Wrapper location={this.props.location} isProject={this.props.isProject} id='index'>
            <StudioMenu studioFilter={this.props.studioFilter} showMenu={this.state.showMenu} currentSlug={this.state.currentSlug} data={posts} />
            <Waypoint 
              topOffset='0' 
              bottomOffset='300px'
              onEnter={() => {self.handleWaypointEnter()}}
              onLeave={() => {self.handleWaypointLeave()}}
            />
            <StudioWrapper ref={this.wrapperRef} showStudioWrapper={showStudioWrapper}>
              {entries.map((post, key) => {
                return (
                  <Waypoint
                    key={post.id} 
                    onEnter={() => {self.handleOnEnter(post.slug, post.id)}}
                    onLeave={() => {self.handleOnLeave(post.slug)}}
                    >
                    <EntryWithRef excerptLength={excerptLength} mobile={this.props.mobile} filter={this.props.studioFilter} data={post} />
                  </Waypoint>
                )
              })}
            </StudioWrapper>
          </Wrapper>
      </React.Fragment>
      )
    }
  }
}

export default StudioView