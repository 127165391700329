import styled, { keyframes } from "styled-components"

var gradientColors = [
  "#1D1D1B",
  "#F0F0F0",
  "#E2E1E1",
  "#D2D2D2",
  "#C3C2C2",
  "#B3B2B2",
  "#A2A1A1",
  "#909090",
  "#7E7F7E",
  "#6C6C6B",
  "#585857",
  "#3D3D3B"
];

export const loaderOne = () => {
  return keyframes`
  0 {
    color: ${gradientColors[0]};
  }
  8.3% {
    color: ${gradientColors[1]};
  }
  16.6% {
    color: ${gradientColors[2]};
  }
  24.9% {
    color: ${gradientColors[3]};
  }
  33.2% {
    color: ${gradientColors[4]};
  }
  41.6% {
    color: ${gradientColors[5]};
  }
  49.9% {
    color: ${gradientColors[6]};
  }
  58.2% {
    color: ${gradientColors[7]};
  }
  66.5% {
    color: ${gradientColors[8]};
  }
  74.8% {
    color: ${gradientColors[9]};
  }
  83.1% {
    color: ${gradientColors[10]};
  }
  91.4% {
    color: ${gradientColors[11]};
  }
  100% {
    color: ${gradientColors[0]};
  }
`;
};

export const loaderTwo = () => {
  return keyframes`
  0 {
    color: ${gradientColors[1]};
  }
  8.3% {
    color: ${gradientColors[2]};
  }
  16.6% {
    color: ${gradientColors[3]};
  }
  24.9% {
    color: ${gradientColors[4]};
  }
  33.2% {
    color: ${gradientColors[5]};
  }
  41.6% {
    color: ${gradientColors[6]};
  }
  49.9% {
    color: ${gradientColors[7]};
  }
  58.2% {
    color: ${gradientColors[8]};
  }
  66.5% {
    color: ${gradientColors[9]};
  }
  74.8% {
    color: ${gradientColors[10]};
  }
  83.1% {
    color: ${gradientColors[11]};
  }
  91.4% {
    color: ${gradientColors[0]};
  }
  100% {
    color: ${gradientColors[1]};
  }
`;
};

export const loaderThree = () => {
  return keyframes`
  0 {
    color: ${gradientColors[2]};
  }
  8.3% {
    color: ${gradientColors[3]};
  }
  16.6% {
    color: ${gradientColors[4]};
  }
  24.9% {
    color: ${gradientColors[5]};
  }
  33.2% {
    color: ${gradientColors[6]};
  }
  41.6% {
    color: ${gradientColors[7]};
  }
  49.9% {
    color: ${gradientColors[8]};
  }
  58.2% {
    color: ${gradientColors[9]};
  }
  66.5% {
    color: ${gradientColors[10]};
  }
  74.8% {
    color: ${gradientColors[11]};
  }
  83.1% {
    color: ${gradientColors[0]};
  }
  91.4% {
    color: ${gradientColors[1]};
  }
  100% {
    color: ${gradientColors[2]};
  }
`;
};

export const loaderFour = () => {
  return keyframes`
  0 {
    color: ${gradientColors[3]};
  }
  8.3% {
    color: ${gradientColors[4]};
  }
  16.6% {
    color: ${gradientColors[5]};
  }
  24.9% {
    color: ${gradientColors[6]};
  }
  33.2% {
    color: ${gradientColors[7]};
  }
  41.6% {
    color: ${gradientColors[8]};
  }
  49.9% {
    color: ${gradientColors[9]};
  }
  58.2% {
    color: ${gradientColors[10]};
  }
  66.5% {
    color: ${gradientColors[11]};
  }
  74.8% {
    color: ${gradientColors[0]};
  }
  83.1% {
    color: ${gradientColors[1]};
  }
  91.4% {
    color: ${gradientColors[2]};
  }
  100% {
    color: ${gradientColors[3]};
  }
`;
};

export const loaderFive = () => {
  return keyframes`
  0 {
    color: ${gradientColors[4]};
  }
  8.3% {
    color: ${gradientColors[5]};
  }
  16.6% {
    color: ${gradientColors[6]};
  }
  24.9% {
    color: ${gradientColors[7]};
  }
  33.2% {
    color: ${gradientColors[8]};
  }
  41.6% {
    color: ${gradientColors[9]};
  }
  49.9% {
    color: ${gradientColors[10]};
  }
  58.2% {
    color: ${gradientColors[11]};
  }
  66.5% {
    color: ${gradientColors[0]};
  }
  74.8% {
    color: ${gradientColors[1]};
  }
  83.1% {
    color: ${gradientColors[2]};
  }
  91.4% {
    color: ${gradientColors[3]};
  }
  100% {
    color: ${gradientColors[4]};
  }
`;
};

export const loaderSix = () => {
  return keyframes`
  0 {
    color: ${gradientColors[5]};
  }
  8.3% {
    color: ${gradientColors[6]};
  }
  16.6% {
    color: ${gradientColors[7]};
  }
  24.9% {
    color: ${gradientColors[8]};
  }
  33.2% {
    color: ${gradientColors[9]};
  }
  41.6% {
    color: ${gradientColors[10]};
  }
  49.9% {
    color: ${gradientColors[11]};
  }
  58.2% {
    color: ${gradientColors[0]};
  }
  66.5% {
    color: ${gradientColors[1]};
  }
  74.8% {
    color: ${gradientColors[2]};
  }
  83.1% {
    color: ${gradientColors[3]};
  }
  91.4% {
    color: ${gradientColors[4]};
  }
  100% {
    color: ${gradientColors[5]};
  }
`;
};

export const loaderSeven = () => {
  return keyframes`
  0 {
    color: ${gradientColors[6]};
  }
  8.3% {
    color: ${gradientColors[7]};
  }
  16.6% {
    color: ${gradientColors[8]};
  }
  24.9% {
    color: ${gradientColors[9]};
  }
  33.2% {
    color: ${gradientColors[10]};
  }
  41.6% {
    color: ${gradientColors[11]};
  }
  49.9% {
    color: ${gradientColors[0]};
  }
  58.2% {
    color: ${gradientColors[1]};
  }
  66.5% {
    color: ${gradientColors[2]};
  }
  74.8% {
    color: ${gradientColors[3]};
  }
  83.1% {
    color: ${gradientColors[4]};
  }
  91.4% {
    color: ${gradientColors[5]};
  }
  100% {
    color: ${gradientColors[6]};
  }
`;
};

export const loaderEight = () => {
  return keyframes`
  0 {
    color: ${gradientColors[7]};
  }
  8.3% {
    color: ${gradientColors[8]};
  }
  16.6% {
    color: ${gradientColors[9]};
  }
  24.9% {
    color: ${gradientColors[10]};
  }
  33.2% {
    color: ${gradientColors[11]};
  }
  41.6% {
    color: ${gradientColors[0]};
  }
  49.9% {
    color: ${gradientColors[1]};
  }
  58.2% {
    color: ${gradientColors[2]};
  }
  66.5% {
    color: ${gradientColors[3]};
  }
  74.8% {
    color: ${gradientColors[4]};
  }
  83.1% {
    color: ${gradientColors[5]};
  }
  91.4% {
    color: ${gradientColors[6]};
  }
  100% {
    color: ${gradientColors[7]};
  }
`;
};

export const loaderNine = () => {
  return keyframes`
  0 {
    color: ${gradientColors[8]};
  }
  8.3% {
    color: ${gradientColors[9]};
  }
  16.6% {
    color: ${gradientColors[10]};
  }
  24.9% {
    color: ${gradientColors[11]};
  }
  33.2% {
    color: ${gradientColors[0]};
  }
  41.6% {
    color: ${gradientColors[1]};
  }
  49.9% {
    color: ${gradientColors[2]};
  }
  58.2% {
    color: ${gradientColors[3]};
  }
  66.5% {
    color: ${gradientColors[4]};
  }
  74.8% {
    color: ${gradientColors[5]};
  }
  83.1% {
    color: ${gradientColors[6]};
  }
  91.4% {
    color: ${gradientColors[7]};
  }
  100% {
    color: ${gradientColors[8]};
  }
`;
};

export const loaderTen = () => {
  return keyframes`
  0 {
    color: ${gradientColors[9]};
  }
  8.3% {
    color: ${gradientColors[10]};
  }
  16.6% {
    color: ${gradientColors[11]};
  }
  24.9% {
    color: ${gradientColors[0]};
  }
  33.2% {
    color: ${gradientColors[1]};
  }
  41.6% {
    color: ${gradientColors[2]};
  }
  49.9% {
    color: ${gradientColors[3]};
  }
  58.2% {
    color: ${gradientColors[4]};
  }
  66.5% {
    color: ${gradientColors[5]};
  }
  74.8% {
    color: ${gradientColors[6]};
  }
  83.1% {
    color: ${gradientColors[7]};
  }
  91.4% {
    color: ${gradientColors[8]};
  }
  100% {
    color: ${gradientColors[9]};
  }
`;
};

export const loaderEleven = () => {
  return keyframes`
  0 {
    color: ${gradientColors[10]};
  }
  8.3% {
    color: ${gradientColors[11]};
  }
  16.6% {
    color: ${gradientColors[0]};
  }
  24.9% {
    color: ${gradientColors[1]};
  }
  33.2% {
    color: ${gradientColors[2]};
  }
  41.6% {
    color: ${gradientColors[3]};
  }
  49.9% {
    color: ${gradientColors[4]};
  }
  58.2% {
    color: ${gradientColors[5]};
  }
  66.5% {
    color: ${gradientColors[6]};
  }
  74.8% {
    color: ${gradientColors[7]};
  }
  83.1% {
    color: ${gradientColors[8]};
  }
  91.4% {
    color: ${gradientColors[9]};
  }
  100% {
    color: ${gradientColors[10]};
  }
`;
};

export const loaderTwelve = () => {
  return keyframes`
  0 {
    color: ${gradientColors[11]};
  }
  8.3% {
    color: ${gradientColors[0]};
  }
  16.6% {
    color: ${gradientColors[1]};
  }
  24.9% {
    color: ${gradientColors[2]};
  }
  33.2% {
    color: ${gradientColors[3]};
  }
  41.6% {
    color: ${gradientColors[4]};
  }
  49.9% {
    color: ${gradientColors[5]};
  }
  58.2% {
    color: ${gradientColors[6]};
  }
  66.5% {
    color: ${gradientColors[7]};
  }
  74.8% {
    color: ${gradientColors[8]};
  }
  83.1% {
    color: ${gradientColors[9]};
  }
  91.4% {
    color: ${gradientColors[10]};
  }
  100% {
    color: ${gradientColors[11]};
  }
`;
};
