const initialState = {
  data: [],
  loaded: false,
  mobile: false,
  breakpointXL: false,
  breakpointXXL: false,
  isProject: false,
  studioFilter: 'All',
  filter: 'all',
  thumbCount: 0,
  thumbLoadCount: 0,
  thumbShowCount: 0,
  loadedThumbs: false,
  showThumbs: false,
  loaderHidden: false, 
  hideMenu: true,
  scrollPos: 0,
  isStudio: false,
  menuAnimating: false,
  showMobileMenu: true
}

export default initialState