import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as actions from "../state/actions/actions";
import { Link } from 'react-router-dom'
import { withRouter } from "react-router-dom"; 
import Loader from './Loader'
import { lockScroll, unlockScroll, setScroll, resetScroll } from '../utils/scroll'
import { MenuWrap, MenuWrapInner, Psuedo, MenuColumn, MenuColumnLayer, MenuColumnText, MenuColumnTextLine } from "../styles/menu";
import { useHistory } from "react-router-dom";
import LandingMenu from './menu/LandingMenu'
import StudioFilters from './menu/StudioFilters'
import MobileMenu from './menu/MobileMenu'

const mapStateToProps = state => {
  return {
    data: state.data,
    studioFilter: state.studioFilter,
    filter: state.filter,
    mobile: state.mobile,
    isProject: state.isProject,
    loadedThumbs: state.loadedThumbs,
    hideMenu: state.hideMenu,
    scrollPos: state.scrollPos,
    isStudio: state.isStudio,
    menuAnimating: state.menuAnimating,
    showMobileMenu: state.showMobileMenu
  }
}

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(actions, dispatch)
  }
}

class Menu extends Component {
  constructor(props) {
    super(props)
    const self = this 
    this.state = {
      hideLoader: false,
      hasLoaded: false,
      loaderFinished: false,
      showMobileMenu: false
    }
    this.menu = React.createRef()
  }

  componentDidUpdate(prevProps, nextProps) {
    const self = this 
    // keep eye on this -- should only bind once
    if (prevProps.data.length === 0) {
      const menu = this.menu.current
      menu.addEventListener('transitionend', (event) => {
        if (event.propertyName === 'height') {
          self.props.actions.unsetMenuAnimating()
          self.props.actions.showMobileMenu()
        }
      })
    }
  }

  render() {
    const self = this 
    const isRoot = this.props.location.pathname === '/'
    const isTerms = this.props.location.pathname === '/agb'
    const isStudioPage = this.props.location.pathname === '/autonomous'
    const isAboutPage = this.props.location.pathname === '/about' || this.props.location.pathname === '/privacy-policy'
    const isProjectsPage = this.props.location.pathname === '/photography'
    const isStudioState = this.props.isStudio
    const rootMenu = this.props.data.length === 0 ? 
      <Loader hideLoader={false} /> 
      : 
      <LandingMenu 
        history={this.props.history} 
        animating={this.props.menuAnimating} 
        mobile={this.props.mobile} 
        data={this.props.data} 
        setMenuAnimating={this.props.actions.setMenuAnimating}
        unsetMenuAnimating={this.props.actions.unsetMenuAnimating}
        hideMobileMenu={this.props.actions.hideMobileMenu}
        showMobileMenu={this.props.actions.showMobileMenu}
      />


    let studioFilterMenu = isStudioPage ? 
      this.props.data.length === 0 
      ? 
      false 
      :
      <StudioFilters mobile={false} setStudioFilter={this.props.actions.setStudioFilter} studioFilter={this.props.studioFilter} data={this.props.data} /> 
    : 
      false 

    return (
      <MenuWrap ref={this.menu} animating={this.props.menuAnimating} isProject={this.props.isProject} isRoot={isRoot} isStudio={isStudioState} hideMenu={this.props.hideMenu}>
        {(() => { 
          if (isRoot) {
            return rootMenu
          }
          else {
            // desktop
            if (!this.props.mobile) {
              let workLink = isStudioState ? '/autonomous' : '/photography'
              let activeWorkState = isStudioState && isStudioPage ? true : isProjectsPage ? true : false
              return (
                <MenuWrapInner isProject={this.props.isProject} animating={this.props.menuAnimating}>
                  <li>
                    <Link to='/'>Mafalda Rakoš</Link>
                  </li>
                  {/* studio filters get rendered conditionally */}
                  {studioFilterMenu}
                  <li>
                    <Link className={activeWorkState ? 'active' : 'inactive'} to={workLink}>Work</Link>
                    <Link className={isAboutPage ? 'active' : 'inactive'} to="/about">About & Contact</Link>
                  </li>
                </MenuWrapInner>
              )
            }
            // mobile
            else {
              return (
                <MobileMenu 
                  {...this.props}
                />
              )
            }
          }
        })()}
      </MenuWrap>
    )
  }

}

export default withRouter(connect(mapStateToProps,mapDispatchToProps)(Menu))
