import axios from 'axios'
// const endpoint = process.env.NODE_ENV === 'production' ? 'cms/api/' : 'http://localhost:8888/api/'
const endpoint = process.env.NODE_ENV === 'production' ? '/cms/api/' : 'http://localhost:8888/api/'

export const SET_DATA = 'SET_DATA'
export const SET_STUDIO_FILTER = 'SET_STUDIO_FILTER'
export const SET_FILTER = 'SET_FILTER'
export const SET_PROJECT = 'SET_PROJECT'
export const UNSET_PROJECT = 'UNSET_PROJECT'
export const SET_DEVICE_WIDTH = 'SET_DEVICE_WIDTH'
export const SET_THUMB_COUNT = 'SET_THUMB_COUNT'
export const LOAD_THUMB = 'LOAD_THUMB'
export const SHOW_THUMB = 'SHOW_THUMB'
export const HIDE_LOADER = 'HIDE_LOADER'
export const HIDE_MENU = 'HIDE_MENU'
export const SHOW_MENU = 'SHOW_MENU'
export const SET_SCROLL_POS = 'SET_SCROLL_POS'
export const SET_STUDIO = 'SET_STUDIO'
export const UNSET_STUDIO = 'UNSET_STUDIO'
export const SET_MENU_ANIMATING = 'SET_MENU_ANIMATING'
export const UNSET_MENU_ANIMATING = 'UNSET_MENU_ANIMATING'
export const SHOW_MOBILE_MENU = 'SHOW_MOBILE_MENU'
export const HIDE_MOBILE_MENU = 'HIDE_MOBILE_MENU'

export function setData(data) {
	return {
		type: 'SET_DATA',
		data
	}
}

export function setStudio() {
	return {
		type: 'SET_STUDIO'
	}
}

export function unsetStudio() {
	return {
		type: 'UNSET_STUDIO'
	}
}

export function checkDeviceWidth(x) {
	return {
		type: "SET_DEVICE_WIDTH",
		x
	};
}

export function setProject() {
	return {
		type: 'SET_PROJECT',
	}
}

export function unsetProject() {
	return {
		type: 'UNSET_PROJECT',
	}
}

export function setStudioFilter(query) {
	return {
		type: 'SET_STUDIO_FILTER',
		query
	}
}

export function setFilter(query) {
	return {
		type: 'SET_FILTER',
		query
	}
}

export function setThumbCount(n) {
	return {
		type: 'SET_THUMB_COUNT',
		n
	}
}

export function loadThumb() {
	return {
		type: 'LOAD_THUMB'
	}
}

export function showThumb() {
	return {
		type: 'SHOW_THUMB'
	}
}

export function hideLoader() {
	return {
		type: 'HIDE_LOADER'
	}
}

export function hideMenu() {
	return {
		type: 'HIDE_MENU'
	}
}

export function showMenu() {
	return {
		type: 'SHOW_MENU'
	}
}

export function setScrollPos(y) {
	return {
		type: 'SET_SCROLL_POS',
		y
	}
}

export function setMenuAnimating() {
	return {
		type: 'SET_MENU_ANIMATING'
	}
}

export function unsetMenuAnimating() {
	return {
		type: 'UNSET_MENU_ANIMATING'
	}
}

export function hideMobileMenu() {
	return {
		type: 'HIDE_MOBILE_MENU'
	}
}

export function showMobileMenu() {
	return {
		type: 'SHOW_MOBILE_MENU'
	}
}

export function getData() {
	return function (dispatch) {
		return axios.get(endpoint + 'home')
			.then((response) => {
				dispatch(setData(response.data))
			})
		}
}