import React, { Component } from "react";
import styled, { keyframes } from "styled-components";
import { typeSmall, typeGeneral, typeBody, typeBodyTitle, typeMedium, typeLarge, typeCaptions } from "../../styles/typography";
import { breakpoint, menuHeights, pageMargin, fadeIn } from "../../styles/utils";
import Markup from "interweave";
import StudioFigure from './StudioFigure'

const SingleImageEl = styled.div`
  grid-column-start: ${props => (props.mobile ? `1` : `2`)};
  grid-column-end: ${props => (props.mobile ? `7` : `6`)};
  margin-bottom: ${props => (props.mobile ? `4rem` : `6rem`)};
  display: flex;
  justify-content: center;
  & .lazyload-wrapper {
    width: ${props => (props.mobile ? `100%` : props.fullwidth ? `100%` : `80%`)};
    @media ${breakpoint.s} {
      width: 100%;
    }
  }
`;

const SingleImage = (props) => {
  let caption = props.data.image.caption.length > 0 ? <figcaption><Markup content={props.data.image.caption}/></figcaption> : false
  let imgWidth = props.mobile ? window.innerWidth : props.data.fullwidth === 'true' ? (window.innerWidth * .67) : ((window.innerWidth * .67) * .8)

  return (
    <SingleImageEl mobile={props.mobile} fullwidth={props.data.fullwidth === 'true' ? true : false}>
      <StudioFigure isSingle={true} width={imgWidth} ratio={props.data.image.ratio} src={props.data.image.thumbnails.l} caption={caption} />
    </SingleImageEl>
  );
}

export default SingleImage