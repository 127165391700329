import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { Route, Switch } from "react-router-dom";
import ProjectContainer from '../containers/ProjectContainer'
import Wrapper from '../components/Wrapper'
import Thumbnail from '../components/Thumbnail'
import Loader from '../components/Loader'
import styled from "styled-components";
import qs from 'qs'
import { pageMargin, breakpoint } from '../styles/utils'
import { typeGeneral } from '../styles/typography'

const ThumbsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-auto-rows: max-content;
  grid-column-gap: ${pageMargin};
  grid-row-gap: ${pageMargin};
  @media ${breakpoint.xs} {
    grid-template-columns: repeat(2, 1fr);
    grid-row-gap: calc(${pageMargin} * 2);
  }
  @media ${breakpoint.s} {
    grid-template-columns: repeat(3, 1fr);
  }
  @media ${breakpoint.m} {
    grid-template-columns: repeat(6, 1fr);
  }
  @media ${breakpoint.l} {
    grid-template-columns: repeat(6, 1fr);
  }
  @media ${breakpoint.xl} {
    grid-template-columns: repeat(6, 1fr);
  }
  @media ${breakpoint.xxl} {
    grid-template-columns: repeat(6, 1fr);
  }
`;


const ProjectsView = ({ actions, location, ...props }) => { 

  if (props.data.length === 0) {
   return <Loader hideLoader={false} />
  }
  else {
    const data = props.data.projects.entries
    let parsed = qs.parse(location.search.slice(1))
    let stateParsed = qs.parse(props.filter.slice(1))
    let parseCondition = location.search.length > 0 ? parsed.filter : props.filter === 'all' ? false : stateParsed.filter
    let totalCount = 0

    return (
      <React.Fragment>
        <Wrapper location={location} isProject={props.isProject} id='index'>
          <ThumbsWrapper isProject={props.isProject}>
            {data.map((i, key) => {
              let link = process.env.PUBLIC_URL + "/photography/" + i.slug
              if (i.slideshow.length) {
                return (
                  <Thumbnail menuAnimating={props.menuAnimating} mobile={props.mobile} link={link} data={i} />
                )
              }
            })}
          </ThumbsWrapper>
        </Wrapper>
        {/* nested route */}
        <Route name="project" path={`${process.env.PUBLIC_URL}/photography/:title`} component={ProjectContainer} />
      </React.Fragment>
    )
  }
}

export default ProjectsView