import styled, { keyframes, css } from "styled-components"
import { pageMargin, breakpoint, menuHeights, topBarHeight, grey } from "./utils";
import { typeGeneral, typeCaptions } from "./typography"

export const TopBar = styled.div`
  position: fixed;
  z-index: 20;
  width: calc(100% - (${pageMargin} * 2));
  top: 0;
  margin: ${pageMargin} 0 0 0;
  display: flex;
  align-items: center;
  & div {
    transition: opacity 0.5s linear;
    opacity: ${props => (props.visible ? "1" : "0")};
    margin-right: 0.5em;
    ${typeGeneral};
    @media ${breakpoint.xs} {
      margin-right: 0;
    }
    &:nth-child(2) {
      transition: color 0.25s ease, opacity 0.5s linear;
      color: ${grey};
      cursor: pointer;
      @media (hover: hover) {
        &:hover {
          color: black;
          @media ${breakpoint.s}, ${breakpoint.xs} {
            color: ${grey};
          }
        }
      }
    }
  }
  & a {
    transition: opacity 0.5s linear;
    opacity: ${props => (props.showClose ? "1" : "0")};
    height: ${topBarHeight};
    width: ${topBarHeight};
    margin-left: auto;
    & img {
      width: 100%;
      height: 100%;
    }
  }
`;

const infoHide = css`
  height: calc(100% - (${pageMargin} * 2));
`;

const infoShow = css`
  height: calc(0.9em + ${pageMargin});
`;

export const ProjectWrap = styled.div`
  z-index: 15;
  position: fixed;
  width: calc(100% - (${pageMargin} * 2));
  display: flex;
  background: none;
  transition: height 0.75s ease-out;
  ${props => (props.showInfo ? infoShow : infoHide)};
  top: ${pageMargin};
  @media ${breakpoint.s}, ${breakpoint.xs} {
    background: none;
  }
`;

export const LoaderWrap = styled.div`
  z-index: 10000;
  position: fixed;
  width: 100%;
  height: 100%;
  background: none;
  display: inline-block;
`;

const growBpXS = () => {
  return keyframes`
    from {
     height: ${menuHeights.xs};
    }
    to {
      height: calc(100% - (${topBarHeight} + ${pageMargin}));
    }
  `;
};

const growBpS = () => {
  return keyframes`
    from {
     height: ${menuHeights.s};
    }
    to {
      height: calc(100% - (${topBarHeight} + ${pageMargin}));
    }
  `;
};

const growBpM = () => {
  return keyframes`
    from {
     height: ${menuHeights.m};
    }
    to {
      height: calc(100% - (${topBarHeight} + ${pageMargin}));
    }
  `;
};

const growBpL = () => {
  return keyframes`
    from {
     height: ${menuHeights.l};
    }
    to {
      height: calc(100% - (${topBarHeight} + ${pageMargin}));
    }
  `;
};

const growBpXL = () => {
  return keyframes`
    from {
     height: ${menuHeights.xl};
    }
    to {
      height: calc(100% - (${topBarHeight} + ${pageMargin}));
    }
  `;
};

const growBpXXL = () => {
  return keyframes`
    from {
     height: ${menuHeights.xxl};
    }
    to {
      height: calc(100% - (${topBarHeight} + ${pageMargin}));
    }
  `;
};

const posTop = css`
  top: calc(${topBarHeight} + ${pageMargin});
`;

const posBottom = css`
  bottom: 0;
`;

export const SlideshowWrap = styled.div`
  border: solid 1px black;
  border-radius: 8px;
  width: 100%;
  position: absolute;
  height: calc(100% - (${topBarHeight} + ${pageMargin}));
  overflow: hidden;
  user-select: none;
  ${props => (props.showClose ? posTop : posBottom)};
  z-index: 2;
  @media ${breakpoint.xs} {
    animation: ${growBpXS} 0.75s ease-out 0.25s backwards;
  }
  @media ${breakpoint.s} {
    animation: ${growBpS} 0.75s ease-out 0.25s backwards;
  }
  @media ${breakpoint.m} {
    animation: ${growBpM} 0.75s ease-out 0.25s backwards;
  }
  @media ${breakpoint.l} {
    animation: ${growBpL} 0.75s ease-out 0.25s backwards;
  }
  @media ${breakpoint.xl} {
    animation: ${growBpXL} 0.75s ease-out 0.25s backwards;
  }
  @media ${breakpoint.xxl} {
    animation: ${growBpXXL} 0.75s ease-out 0.25s backwards;
  }
  & #transitionWrap {
    height: 100%;
    width: 100%;
  }
`;

// height of top bar + the 2 margins which surround it
export const ProjectInfo = styled.div`
  margin-top: calc(${topBarHeight} + (${pageMargin} * 2));
  top: 0;
  position: fixed;
  padding-top: ${pageMargin};
  padding-bottom: calc(${topBarHeight} + (${pageMargin} * 2));
  width: calc(100% - (${pageMargin} * 2));
  transition: height 0.75s linear, opacity 0.5s linear;
  background: none;
  height: 100vh;
  opacity: ${props => (props.showInfo ? "1" : "0")};
  z-index: 14;
  overflow: scroll;
  @media ${breakpoint.s}, ${breakpoint.xs} {
    height: 100%;
  }
  & p {
    width: 50%;
    ${typeGeneral};
    margin-bottom: 1em;
    @media ${breakpoint.xs} {
      width: 100%;
    }
    @media ${breakpoint.landscape} {
      width: 75%;
    }
    & a {
      transition: color 0.25s ease;
      color: ${grey};
      text-decoration: none;
      @media (hover: hover) {
        &:hover {
          color: black;
          @media ${breakpoint.s}, ${breakpoint.xs} {
            color: ${grey};
          }
        }
      }
    }
  }
`;