import React, { Component } from 'react'
import { Link } from "react-router-dom"
import { Markup, Filter } from "interweave"
import ReactPlayer from "react-player"
import SlideWrapper from '../components/SlideWrapper';
import Loader from '../components/Loader'
import { CSSTransition, TransitionGroup } from "react-transition-group";
import qs from 'qs'
import { lockScroll, unlockScroll } from "../utils/scroll";
import {
  TopBar,
  LoaderWrap,
  ProjectWrap,
  SlideshowWrap,
  ProjectInfo,
} from '../styles/project'

class ProjectView extends Component {
  constructor(props) {
    super(props);
    const self = this;
    this.slider = ''
    this.showInfo = false;
    this.state = {
      showInfo: false,
      infoAnimFinished: false,
      visible: false,
      showClose: false, 
      imageIndex: 0,
      showLoader: false,
      leftCursor: false,
      transition: true
    };
    this.handleKeyPress = (event) => {
      if (event.keyCode == 37) {
        self.navigateSlides(false)
      }
      else if (event.keyCode == 39) {
        self.navigateSlides(true)
      }
      else if (event.keyCode == 27) {
        self.props.filter === 'all' ? self.props.history.push('/') : self.props.history.push('/' + self.props.filter)
        var info = document.getElementById('projectInfo')
        unlockScroll(info)
      }
      else {
        return false
      }

    }
    this.handleAnimEnd = (event) => {
      if (event.target.id === 'slideshowWrap') {
        self.setState({ 
          visible: true,
          showClose: true
        })
      }
    }
    this.handleTransitionEnd = (event) => {
      if (event.propertyName === 'height' && self.state.showInfo) {
        // seems to work better than state changing prop
        var slidewrap = document.getElementById('slideshowWrap')
        slidewrap.style.border = 'solid .5px black'
        slidewrap.style.borderTop = 0
      }
    }
  }

  componentDidMount() {
    const self = this 
    this.setState({
      imageIndex: this.props.imageIndex
    });

    if (this.props.data.slideshow.length <= 1) {
      this.setState({
        transition: false 
      })
    }
    else {
      this.setState({
        transition: true 
      })
    }

    var info = document.getElementById('projectInfo')
    lockScroll(info)

    window.addEventListener('keydown', self.handleKeyPress)
    window.addEventListener("animationend", self.handleAnimEnd)
    window.addEventListener("webkitAnimationEnd", self.handleAnimEnd);
    window.addEventListener("oanimationend", self.handleAnimEnd);
    window.addEventListener("MSAnimationEnd", self.handleAnimEnd);

    window.addEventListener("transitionend", self.handleTransitionEnd)
    window.addEventListener('webkitTransitionEnd', self.handleTransitionEnd)
    window.addEventListener("otransitionend", self.handleTransitionEnd);
    window.addEventListener("MSTransitionEnd", self.handleTransitionEnd);
  }

  componentWillUnmount() {
    const self = this
    var info = document.getElementById('projectInfo')
    unlockScroll(info)

    window.removeEventListener('keydown', self.handleKeyPress)

    window.removeEventListener("animationend", self.handleAnimEnd)
    window.removeEventListener("webkitAnimationEnd", self.handleAnimEnd);
    window.removeEventListener("oanimationend", self.handleAnimEnd);
    window.removeEventListener("MSAnimationEnd", self.handleAnimEnd);
    
    window.removeEventListener("transitionend", self.handleTransitionEnd)
    window.removeEventListener('webkitTransitionEnd', self.handleTransitionEnd)
    window.removeEventListener("otransitionend", self.handleTransitionEnd)
    window.removeEventListener("MSTransitionEnd", self.handleTransitionEnd);

  }

  componentDidUpdate(prevProps) {
    const self = this 
    if (prevProps.data.id !== this.props.data.id) {
      if (this.props.data.slideshow.length <= 1) {
        this.setState({
          transition: false 
        })
      }
      else {
        this.setState({
          transition: true 
        })
      }

      var prevId = parseInt(prevProps.data.id)
      var newId = parseInt(this.props.data.id)
      this.setState({
        imageIndex: prevId > newId ? this.props.data.slideshow.length - 1 : 0,
        showLoader: true
      });
      setTimeout(() => {
        self.setState({
          showLoader: false,
          visible: true
        });
      }, 2500)
    }
  }

  // should be a prototype fn?
  buildSlide(data, key) {
    var slideObj = {
      url: data.type === 'image' ? data.thumbnails.l : data.url,
      caption: data.caption ? data.caption : false,
      ratio: data.ratio,
      type: data.type,
      key: key
    };
    return slideObj;
  }

  returnSlideshow(arr) {
    const self = this;
    var slides = [];
    arr.map((x, key) => {
      slides.push(self.buildSlide(x, key))
    });
    return slides
  }

  navigateSlides(fwd) {
    var max = this.props.data.slideshow.length
    var current = parseInt(this.props.data.id - 1)
    var index = this.props.index
    var next = current + 1;
    var prev = current - 1;
    var nextProject = index[next] ? index[next].slug : false;
    var prevProject = index[prev] ? index[prev].slug : false;
    var parsedFilter = this.props.filter === 'all' ? 'all' : qs.parse(this.props.filter.slice(1))

    // if (max > 1) {
    //   this.setState({
    //     transition: true 
    //   })
    // }

    if (fwd) {
      if (this.state.imageIndex >= max - 1) {
        if (nextProject !== false) {
          if (this.props.filter !== "all") {

            var nextProjects = Object.keys(index)
              .slice(next, index.length)
              .map(key => {
                return index[key];
              });

            var nextProjectMatch = nextProjects.filter(function(key) {
              return key.types.indexOf(parsedFilter.filter) !== -1
                ? key
                : false;
            });

            var nextFilteredSlug = nextProjectMatch.length
              ? nextProjectMatch[0].slug
              : false;

            if (nextFilteredSlug !== false) {
              this.setState({
                visible: false
              });
            }

            nextFilteredSlug !== false
              ? this.props.history.push(process.env.PUBLIC_URL + "/photography/" + nextFilteredSlug)
              : false;
          } else {
            this.setState({
              visible: false
            });
            this.props.history.push(process.env.PUBLIC_URL + "/photography/" + nextProject);
          }
        }
      } else {
        this.setState({
          imageIndex: (this.state.imageIndex += 1)
        });
      }
    } else {
      if (this.state.imageIndex <= 0) {
        if (prevProject !== false) {
          if (this.props.filter !== "all") {
            var prevProjects = Object.keys(index)
              .slice(0, current)
              .map(key => {
                return index[key];
              });

            var prevProjectMatch = prevProjects.filter(function(key) {
              return key.types.indexOf(parsedFilter.filter) !== -1
                ? key
                : false;
            });
            var last = Object.keys(prevProjectMatch).length - 1;
            var prevFilteredSlug = prevProjectMatch.length
              ? prevProjectMatch[last].slug
              : false;

            if (prevFilteredSlug !== false) {
              this.setState({
                visible: false
              });
            }
            prevFilteredSlug !== false
              ? this.props.history.push("/photography/" + prevFilteredSlug)
              : false;
          } else {
            this.setState({
              visible: false
            });
            this.props.history.push("/photography/" + prevProject);
          }
        }
      } else {
        this.setState({
          imageIndex: (this.state.imageIndex -= 1)
        });
      }
    }
  }

  handleSlideClick(event) {
    var ww = window.innerWidth
    var mouseX = event.pageX
    var dir = mouseX > ww / 2 ? true : false 
    // if not link in caption
    if (event.target.tagName !== 'A') {
      this.navigateSlides(dir)
    }
  }

  handleInfoClick() {
    this.setState({
      showInfo: this.state.showInfo ? false : true
    })
    if (this.state.showInfo) {
      // seems to work better than state changing prop
      var slidewrap = document.getElementById('slideshowWrap')
      slidewrap.style.border = 'solid 1px black'
    }
  }


  render() {
    if (!this.props.data) {
      return false
    } else {
      const self = this;
      var mobileTitle = this.props.mobile ? <p>{this.props.data.title}</p> : false
      var indexPath =
        this.props.filter === "all" ? process.env.PUBLIC_URL + "/" : process.env.PUBLIC_URL + "/" + this.props.filter;
      var mobileTitle = this.props.mobile ? (
        <p>{this.props.data.title}</p>
      ) : (
        false
      );

      var title = this.props.mobile ? false : this.state.visible ? this.props.data.title + ',' + ' ' + this.props.data.year + " " : <span>&nbsp;</span>
      var arrow = this.props.mobile ? false : this.state.visible ? '↘' : <span>&nbsp;</span>
      var info = !this.state.visible ? <span>&nbsp;</span> : this.state.showInfo ? 'Back to Images' : 'Project Information'
      var linkDest = this.props.isStudio ? '/autonomous' : '/photography'

      return (
        <React.Fragment>
          <TopBar visible={this.state.visible} showClose={this.state.showClose}>
            <div>
              {title}
              <span id="oracle">{arrow}</span>
            </div>
            <div onClick={this.handleInfoClick.bind(this)}>{info}</div>
            {/* this could be conditional based on isStudio global state */}
            <Link to={linkDest}>
              {" "}
              <img src={process.env.PUBLIC_URL + "/close.svg"} />
            </Link>
          </TopBar>

          <ProjectWrap showInfo={this.state.showInfo}>
            <SlideshowWrap id="slideshowWrap" showClose={this.state.showClose} showInfo={this.state.showInfo} onClick={this.handleSlideClick.bind(this)} animFinished={this.state.infoAnimFinished}>
              
              {this.state.showLoader ?
                <LoaderWrap>
                  <Loader hideLoader={false} />
                </LoaderWrap>
                :
                false
              }
              
              <SlideWrapper
                transition={this.state.transition}
                mobile={this.props.mobile}
                visible={this.state.visible}
                slug={this.props.slug}
                imageIndex={this.state.imageIndex}
                slideshow={this.returnSlideshow(this.props.data.slideshow)}
              />
            </SlideshowWrap>

          </ProjectWrap>

          <ProjectInfo id = "projectInfo" showInfo={this.state.showInfo} showInfoText={this.state.showInfoText}>
            {mobileTitle}
            <Markup content={this.props.data.description} />
          </ProjectInfo>


        </React.Fragment>
      );
    }
  }
}

export default ProjectView