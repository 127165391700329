import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';

export const unlockScroll = (target) => {
  enableBodyScroll(target);
  clearAllBodyScrollLocks()
}

export const lockScroll = (target) => {
  disableBodyScroll(target);
}

export const resetScroll = () => {
  window.scrollTo(0, 0)
}

export const setScroll = (x) => {
  window.scrollTo(0, x)
}