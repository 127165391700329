import React, { Component } from "react"
import { MenuWrap, Psuedo, MenuColumn, MenuColumnLayer, MenuColumnText, MenuColumnTextLine } from "../../styles/menu"

class LandingMenu extends Component {
  constructor(props) {
    super(props)
    this.state = {
      studioHovered: false,
      projectsHovered: false
    }
  }

  handleStudioMouseOver() {
    this.props.mobile ? false : this.props.animating ? false : !this.state.studioHovered ? this.setState({ studioHovered: true }) : false
  }

  handleStudioMouseMove() {
    this.props.mobile ? false : this.props.animating ? false : !this.state.studioHovered ? this.setState({ studioHovered: true }) : false
  }

  handleStudioMouseLeave() {
    this.props.mobile ? false : this.props.animating ? false : this.state.studioHovered ? this.setState({ studioHovered: false }) : false
  }

  handleProjectsMouseOver() {
    this.props.mobile ? false : this.props.animating ? false : !this.state.projectsHovered ? this.setState({ projectsHovered: true }) : false
  }

  handleProjectsMouseMove() {
    this.props.mobile ? false : this.props.animating ? false : !this.state.projectsHovered ? this.setState({ projectsHovered: true }) : false
  }

  handleProjectsMouseLeave() {
    this.props.mobile ? false : this.props.animating ? false : this.state.projectsHovered ? this.setState({ projectsHovered: false }) : false
  }

  handleLinkClick(e, route) {
    this.props.setMenuAnimating()
    this.props.hideMobileMenu()
    e.preventDefault()
    const self = this

    // throw to bottom of stack
    setTimeout(() => {
      self.props.history.push(route)
    }, 50)
  }

  render() {
    const studioBackground = this.props.mobile ? this.props.data.studio.mobileimage.thumbnails.mobile : this.props.data.studio.image.thumbnails.desktop 
    const projectsBackground = this.props.mobile ? this.props.data.projects.mobileimage.thumbnails.mobile : this.props.data.projects.image.thumbnails.mobile 
    const studioFilters = this.props.data.studio.filters.map((filter, key) => {
      return <MenuColumnTextLine className='white'>{filter}</MenuColumnTextLine>
    })
    const projectsFilters = this.props.data.projects.filters.map((filter, key) => {
      return <MenuColumnTextLine className='white'>{filter}</MenuColumnTextLine>
    })
    return (
      <React.Fragment>
        <MenuColumn animating={this.props.animating} onMouseMove={this.handleStudioMouseMove.bind(this)} onMouseOver={this.handleStudioMouseOver.bind(this)} onMouseLeave={this.handleStudioMouseLeave.bind(this)}>
          <a onClick={(event) => this.handleLinkClick(event, '/autonomous')}>
            <MenuColumnLayer animating={this.props.animating} zIndex={1} showLayer={true} background={studioBackground}>
              <MenuColumnText>
                <MenuColumnTextLine className='black'>Mafalda Rakoš</MenuColumnTextLine>
                <MenuColumnTextLine className='black'>{this.props.data.studio.title}</MenuColumnTextLine>
              </MenuColumnText>
            </MenuColumnLayer>
            <MenuColumnLayer zIndex={2} showLayer={this.state.studioHovered} background={studioBackground}>
              <MenuColumnText className={this.state.studioHovered ? 'visible' : 'hidden'}>
                {studioFilters}
              </MenuColumnText>
            </MenuColumnLayer>
          </a>
        </MenuColumn>
        <MenuColumn animating={this.props.animating} onMouseMove={this.handleProjectsMouseMove.bind(this)} onMouseOver={this.handleProjectsMouseOver.bind(this)} onMouseLeave={this.handleProjectsMouseLeave.bind(this)}>
          <a onClick={(event) => this.handleLinkClick(event, '/photography')}>
            <MenuColumnLayer animating={this.props.animating} zIndex={1} showLayer={true} background={projectsBackground}>
              <MenuColumnText>
                <MenuColumnTextLine className='black'>Mafalda Rakoš</MenuColumnTextLine>
                <MenuColumnTextLine className='black' color='black'>{this.props.data.projects.title}</MenuColumnTextLine>
              </MenuColumnText>
            </MenuColumnLayer>
            <MenuColumnLayer zIndex={2} showLayer={this.state.projectsHovered} background={projectsBackground}>
              <MenuColumnText className={this.state.projectsHovered ? 'visible' : 'hidden'}>
                {projectsFilters}
              </MenuColumnText>
            </MenuColumnLayer>
          </a>
        </MenuColumn>
      </React.Fragment>
    )
  }
}

export default LandingMenu
