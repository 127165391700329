import React, { Component } from "react";
import styled, { keyframes } from "styled-components";
import { typeSmall, typeGeneral, typeBody, typeBodyTitle, typeMedium, typeLarge, typeCaptions } from "../../styles/typography";
import { breakpoint, menuHeights, pageMargin, fadeIn } from "../../styles/utils";
import TextImage from './TextImage'
import ImageText from './ImageText'
import SingleImage from './SingleImage'
import SingleText from './SingleText'
import DoubleImage from './DoubleImage'
import VimeoEmbed from './VimeoEmbed'

// scroll-margin-top is optical -- ensure it works cross browser
const StudioEntryEl = styled.section`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-column-gap: ${pageMargin};
  scroll-margin-top: 6rem;
  position: relative;
  height: auto;
  &:not(:last-child) {
    margin-bottom: ${props => (!props.visible ?  `0` : props.mobile ? `8rem` : `5rem`)};
  }
`;

const StudioEntry = (props) => {
  const self = this
  let tags = []
  props.data.layout.map((entry, key) => {
    entry.tags.map((tag, x) => {
      tags.push(tag)
    })
  })
  const visible = props.filter === 'All' ? true : tags.includes(props.filter) ? true : false
  const entryClass = visible ? 'visible' : 'hidden'
  if (visible) {
    return (
        <StudioEntryEl className={entryClass} visible={visible} mobile={props.mobile} id={props.data.slug} ref={props.innerRef}>
          {props.data.layout.map((i, key) => {
            switch(i.type) {
              case 'single-text' : 
                return props.filter === 'All' || i.tags.includes(props.filter) ? <SingleText excerptLength={props.excerptLength} mobile={props.mobile} data={i.data} /> : false
                break
              case 'single-image' : 
                return props.filter === 'All' || i.tags.includes(props.filter) ? <SingleImage mobile={props.mobile} data={i.data} /> : false
                break
              case 'double-image' : 
                return props.filter === 'All' || i.tags.includes(props.filter) ? <DoubleImage mobile={props.mobile} data={i.data} /> : false
                break
              case 'image-text' : 
                return props.filter === 'All' || i.tags.includes(props.filter) ? <ImageText mobile={props.mobile} data={i.data} /> : false
                break
              case 'text-image' : 
                return props.filter === 'All' || i.tags.includes(props.filter) ? <TextImage mobile={props.mobile} data={i.data} /> : false
                break
              case 'vimeo-embed' :
                return props.filter === 'All' || i.tags.includes(props.filter) ? <VimeoEmbed mobile={props.mobile} data={i.data} /> : false
              default:
                return false
            }
          })}
      </StudioEntryEl>
    )
  }
  else {
    return false 
  }
}

export default StudioEntry