import React, { Component } from "react";
import styled, { keyframes } from "styled-components";
import { typeSmall, typeGeneral, typeBody, typeBodyTitle, typeMedium, typeLarge, typeCaptions } from "../../styles/typography";
import { breakpoint, menuHeights, pageMargin, fadeIn } from "../../styles/utils";
import Markup from "interweave";
import StudioFigure from './StudioFigure'

const EmbedWrapper = styled.div`
  grid-column-start: ${props => (props.mobile ? `1` : `2`)};
  grid-column-end: ${props => (props.mobile ? `7` : `6`)};
  margin-bottom: ${props => (props.mobile ? `4rem` : `6rem`)};
  display: flex;
  justify-content: center;
`;

const EmbedInner = styled.div`
  width: 100%;
  display: flex;
  flex-flow: column;
  & figcaption {
    ${typeCaptions};
  }
`;

const EmbedFrame = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 56.25%;
  margin-bottom: calc(${pageMargin} / 2);
  & iframe {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
  }
`;


const VimeoEmbed = (props) => {
  let caption = props.data.vimeocaption.length > 0 ? <figcaption><Markup content={props.data.vimeocaption}/></figcaption> : false
  let vimeoSrc = 'https://player.vimeo.com/video/' + props.data.vimeoid + '?byline=0&title=0&sidedock=0'
  return (
    <EmbedWrapper mobile={props.mobile} fullwidth={true}>
      <EmbedInner>
        <EmbedFrame>
          <iframe src={vimeoSrc}></iframe>
        </EmbedFrame>
        <figcaption>{caption}</figcaption>
      </EmbedInner>
    </EmbedWrapper>
  );
}

export default VimeoEmbed