import React, { Component } from "react"
import { MenuWrap, MobileMenuWrap, MenuWrapInner, MobileMenuBtn, MobileMenuSwitch, MobileMenuSwitchItem, MenuStudioFilter, Psuedo, MenuColumn, MenuColumnLayer, MenuColumnText, MenuColumnTextLine } from "../../styles/menu"
import { Link } from 'react-router-dom'
import StudioFilters from './StudioFilters'
import { lockScroll, unlockScroll, setScroll, resetScroll } from '../../utils/scroll'

class MobileMenu extends Component {
  constructor(props) {
    super(props)
    this.state = {
      open: false,
      isTransitioning: false
    }
  }

  handleClick() {
    !this.props.hideMenu ? unlockScroll() : lockScroll()
    !this.props.hideMenu ? this.props.actions.hideMenu() : this.props.actions.showMenu()
  }

  handleStudioClick() {
    if (!this.props.isStudio) {
      // could also render <a> tags conditionally in jsx
      this.props.history.push('/autonomous')
      this.props.actions.setStudio()
    }
    else {
      this.props.actions.setStudio()
    }
  }

  handleProjectsClick() {
    if (this.props.isStudio) {
      // could also render <a> tags conditionally in jsx
      this.props.history.push('/photography')
      this.props.actions.unsetStudio()
    }
    else {
      this.props.actions.unsetStudio()
    }
  }

  render() {
    console.log('mobile menu props', this.props)

    const isStudioPage = this.props.location.pathname === '/autonomous'
    const isStudioState = this.props.isStudio
    const isAboutPage = this.props.location.pathname === '/about'
    const isProjectsPage = this.props.location.pathname === '/photography'
    const studioFilters = isStudioPage && !this.props.menuAnimating ? 
      <StudioFilters hideMenu={this.props.actions.hideMenu} mobile={true} setStudioFilter={this.props.actions.setStudioFilter} studioFilter={this.props.studioFilter} data={this.props.data} />
      :
      false
    const workLink = this.props.isStudio ? '/autonomous' : '/photography'
    const activeWorkState = isStudioState && isStudioPage ? true : isProjectsPage ? true : false

    return (
      <MobileMenuWrap isProject={this.props.isProject}>
        {this.props.isProject ?
          false
        :
        <MobileMenuBtn onClick={this.handleClick.bind(this)}>
          {!this.props.hideMenu ? this.state.isTransitioning ? '' : 'Close' : 'Menu'}
        </MobileMenuBtn>
        }
        <MenuWrapInner showMobileMenu={this.props.showMobileMenu}>
          <MobileMenuSwitch className='switch'>
            <MobileMenuSwitchItem onClick={this.handleStudioClick.bind(this)} isActive={isStudioState}>Autonomous</MobileMenuSwitchItem>
            <MobileMenuSwitchItem onClick={this.handleProjectsClick.bind(this)} isActive={!isStudioState}>Photography</MobileMenuSwitchItem>
          </MobileMenuSwitch>
          
          <li><Link className={activeWorkState ? 'active' : 'inactive'} to={workLink}>Work</Link></li>
          {studioFilters}
          <li><Link className={isAboutPage ? 'active' : 'inactive'} to="/about">About & Contact</Link></li>
        </MenuWrapInner>
      </MobileMenuWrap>
    )
  }
}

export default MobileMenu
