import { createGlobalStyle } from "styled-components";
import { pageMargin, breakpoint, grey } from './utils'
import { typeCaptions } from './typography'

const GlobalStyle = createGlobalStyle`
  html {
    -webkit-text-size-adjust: 100%;
    font-smooth: always;
    -moz-osx-font-smoothing: always;
    @media ${breakpoint.xs} { 
      font-size: 17px;
    }
    @media ${breakpoint.s} { 
      font-size: 17px;
    }
    @media ${breakpoint.m} { 
      font-size: 18px;
    }
    @media ${breakpoint.l} { 
      font-size: 20px;
    }
    @media ${breakpoint.xl} { 
      font-size: 20px;
    }
    @media ${breakpoint.xxl} { 
      font-size: 24px;
    }
  }

  body {
    width: 100%;
    height: 100%;
    font-family: Gaisyr, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    padding: ${pageMargin};
    margin: 0;
    overflow-y: scroll;
    overflow-x: hidden;
  }

  #root {
    position: relative;
    width: 100%;
    height: 100%;
  }

  .page {
    position: relative;
    height: 100%;
  }

  * > p {
    margin-bottom: 1em;
  }

  .react-player {
    position: absolute;
    top: 0;
    left: 0;
  }
  
  .active {
    color: ${grey} !important;
    & a {
      color: ${grey} !important;
    }
  }
  
  #oracle {
    font-family: 'Oracle' !important;
  }

  .player-wrapper {
    width: 60%;
    height: auto;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    @media ${breakpoint.xs} { 
      width: calc(100% - (${pageMargin} * 2));
    }
    @media ${breakpoint.landscape} { 
      width: 50%;
    }
  }

  .player-wrapper-inner {
    position: relative;
    padding-top: 56.25%;
    height: 0;
    overflow: hidden;
  }

  .react-player {
    width: 100% !important;
    height: 100% !important;
  }

  .player-wrapper figcaption {
    ${typeCaptions};
    left: 0;
    width: 100%;
    text-align: center;
    margin-top: 1em;
    display: block;
  }

  .react-player iframe, .react-player object, .react-player embed { 
	  position: absolute;
	  top: 0;
	  left: 0;
	  width: 100%;
	  height: 100%;
  }  

`;

export default GlobalStyle