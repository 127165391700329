import React, { Component, useState } from "react";
import styled, { keyframes } from "styled-components";
import { typeSmall, typeGeneral, typeBody, typeBodyTitle, typeMedium, typeLarge, typeCaptions } from "../../styles/typography";
import { breakpoint, menuHeights, pageMargin, fadeIn, grey } from "../../styles/utils";
import Markup from "interweave";
import ReactReadMoreReadLess from "react-read-more-read-less";


const SingleTextEl = styled.div`
  grid-column-start: 1;
  grid-column-end: 7;
  display: flex;
  justify-content: center;
  margin-bottom: ${props => (props.mobile ? `4rem` : `6rem`)};
`;

const SingleTextInner = styled.div`
  width: ${props => (props.mobile ? `100%` : props.columns ? `75%` : `40%`)};
  @media ${breakpoint.s} {
    width: 60%;
  }
  & h1 {
    ${typeBodyTitle};
    text-align: center;
    margin-bottom: 3rem;
  }
`;

const SingleTextParagraph = styled.div`
  width: 100%;
  columns: ${props => (props.mobile ? `100%` : props.columns ? `2` : `1`)};
  margin-bottom: 1em;
  @media ${breakpoint.s} {
    columns: 1;
  }
  & p {
    ${typeBody};
    &:last-child {
      margin-bottom: 0 !important;
    }
    & a {
      color: ${grey};
      text-decoration: none;
      transition: color 0.25s ease;
      @media (hover: hover) {
        &:hover {
          color: black;
        }
      }
    }
  }
`;

const SingleTextBtn = styled.div`
  ${typeGeneral};
  color: ${grey};
  transition: color 0.25s ease;
  cursor: pointer;
  font-size: .9em;
  @media (hover: hover) {
    &:hover {
      color: black;
    }
  }
`;

const excerptText = (txt, max) => {
  return txt.substring(0, max) + '...'
}

const SingleText = (props) => {

  const [expanded, setExpanded] = useState(false);
  const heading = props.data.heading.length > 0 ? <h1>{props.data.heading}</h1> : false
  const textLength = props.data.text.length 
  // const expandable = props.data.columns === 'true' ? false : props.data.text.length <= props.excerptLength ? false : true
  const expandable = props.data.text.length <= props.excerptLength ? false : true

  const dataText = expandable ? !expanded ? excerptText(props.data.text, props.excerptLength) : props.data.text : props.data.text
  const buttonText = expanded ? 'Read Less' : 'Read More'
  const readBtn = expandable ? <SingleTextBtn onClick={(event) => handleClick()}>{buttonText}</SingleTextBtn> : false

  const handleClick = () => {
    expanded ? setExpanded(false) : setExpanded(true)
  }

  return (
    <SingleTextEl mobile={props.mobile}>
      <SingleTextInner mobile={props.mobile} columns={props.data.columns === 'true' ? true : false }>
        {heading}
        <SingleTextParagraph mobile={props.mobile} columns={props.data.columns === 'true' ? true : false }>
          <Markup content={dataText} />
        </SingleTextParagraph>
        {readBtn}
      </SingleTextInner>
    </SingleTextEl>
  );
}

export default SingleText