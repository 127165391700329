import styled, { keyframes } from "styled-components";
import { pageMargin, breakpoint, grey } from "./utils";
import { typeGeneral, typeSmall } from "../styles/typography";

export const PageTitle = styled.div`
  ${typeGeneral};
  width: 100%;
  position: sticky;
  display: block;
  border: solid 1px black;
  border-top: 0;
  border-right: 0;
  border-left: 0;
  margin-top: -${pageMargin};
  padding-top: ${pageMargin};
  padding-bottom: calc(${pageMargin} / 2);
  top: 0;
  background: white;
  z-index: 3;
  display: flex;
  justify-content: space-between;
  & a {
    width: .75rem;
    height: .75rem;
    & img {
      width: 100%;
      height: 100%;
    }
  }
`;

export const TextBlock = styled.div`
  width: 100%;
  position: relative;
  margin-top: 0.5em;
  padding-bottom: ${props => (props.list ? '2em' : '1em')};
  border: ${props => (props.lastChild ? 'none' : 'solid 1px black')};
  border-top: 0;
  border-right: 0;
  border-left: 0;
  & p {
    text-indent: ${props => (props.list ? '-3rem' : '0')};
    padding-left: ${props => (props.list ? '3rem' : '0')};
    margin-bottom: ${props => (props.noMargin ? '0 !important' : '1em')};
    ${typeGeneral};
    & a {
      color: black;
      text-decoration: none;
      transition: color 0.25s ease;
      @media (hover: hover) {
        &:hover {
          color: ${grey};
          @media ${breakpoint.s}, ${breakpoint.xs} {
            color: black;
          }
        }
      }
    }
  }
`;

export const Grid = styled.div`
  ${typeGeneral};
  width: 100%;
  position: relative;
  padding-bottom: 1em;
  display: flex;
  @media ${breakpoint.s}, ${breakpoint.xs} {
    display: inline-block;
  }
`;

export const PolicyLinks = styled.div`
  margin-bottom: 1em;
  & a {
    color: black;
    text-decoration: none;
    transition: color 0.25s ease;
    &:hover {
      color: ${grey};
    }
  }
`

export const Policy = styled.div`
  width: 100%;
  position: relative;
  column-count: 3;
  @media ${breakpoint.s}, ${breakpoint.xs} {
    column-count: 1;
  }
  margin-top: 0.5em;
  padding-bottom: 1em;
  & p {
    ${typeSmall};
    margin-bottom: 1em;
  }
`;

// li el uses 2em for large bullets, span uses rem size so it refers to body el
// .6 line-height = 1.2 (halfed)
// .5 font size for span = 1rem
export const Col = styled.div`
  width: calc(50% - ${pageMargin});
  margin-right: ${pageMargin};
  position: relative;
  @media ${breakpoint.s}, ${breakpoint.xs} {
    border: solid 1px black;
    border-top: 0;
    border-right: 0;
    border-left: 0;
    width: calc(100% - ${pageMargin});
    &:last-child {
      border: none;
    }
    &:first-child h1 {
      margin-top: 0 !important;
    }
  }

  & h1 {
    ${typeGeneral};
    margin-bottom: 1em;
    @media ${breakpoint.s}, ${breakpoint.xs} {
      margin-top: 0.5em;
    }
  }
`;

// width of year is optical
export const InfoList = styled.ul`
  list-style: none;
  padding: 0;
  & li {
    display: flex;
    list-style: none;
    width: 100%;
    height: 100%;
    & a {
      color: black;
      text-decoration: none;
      transition: color 0.25s ease;
      &:hover {
        color: ${grey};
      }
    }
    & span.year {
      flex-shrink: 0;
      &.single {
        width: 2rem;
        margin-right: 1.25em;
      }
      &.double {
        width: 5.375rem;
        margin-right: .25em;
      }
    }
  }
`

export const TermsWrap = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  z-index: 101;
  & embed {
    width: 100%;
    height: 100%;
  }
`