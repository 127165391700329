import React, { Component } from "react"
import { MenuWrap, MenuStudioFilter, Psuedo, MenuColumn, MenuColumnLayer, MenuColumnText, MenuColumnTextLine } from "../../styles/menu"
import { lockScroll, unlockScroll, setScroll, resetScroll } from '../../utils/scroll'

class StudioFilters extends Component {
  constructor(props) {
    super(props)
  }

  handleLinkClick(e, route) {
    e.preventDefault()
  }

  handleFilterClick(e, tag) {
    e.preventDefault()
    this.props.setStudioFilter(tag)
    if (this.props.mobile) {
      this.props.hideMenu()
      unlockScroll()
    }
  }

  componentWillUnmount() {
    const self = this 
    // look out for timeout here
    setTimeout(() => {
      self.props.setStudioFilter('All')
    }, 1000)
  }

  render() {
    const self = this
    let filters = this.props.data.length === 0 ? false : this.props.data.studio.filters.map((filter, key) => {
      return <MenuStudioFilter isActive={self.props.studioFilter === filter} onClick={(event) => {self.handleFilterClick(event, filter)}}>{filter}</MenuStudioFilter>
    })

    let file = this.props.data.length === 0 ? false : this.props.data.studio.portfolio
    
    if (!this.props.mobile) {
      return (
        <li>
          <MenuStudioFilter isActive={this.props.studioFilter === 'All'} onClick={(event) => {self.handleFilterClick(event, 'All')}}>All</MenuStudioFilter>
          {filters}
          <MenuStudioFilter><a href={file} download>Download Portfolio</a></MenuStudioFilter>
        </li>
      )
    }
    else {
      return (
        <React.Fragment>
          <MenuStudioFilter isActive={this.props.studioFilter === 'All'} onClick={(event) => {self.handleFilterClick(event, 'All')}}>All</MenuStudioFilter>
          {filters}
          <MenuStudioFilter><a href={file} download>Download Portfolio</a></MenuStudioFilter>
        </React.Fragment>
      )
    }
  }
}

export default StudioFilters
