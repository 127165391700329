import React, { Component } from "react";
import styled, { keyframes } from "styled-components";
import { breakpoint, menuHeights, pageMargin, fadeIn } from "../styles/utils";
import { TransitionGroup, CSSTransition } from "react-transition-group";

// Wrapper now has absolute positioning
// as .page wraps it with relative
// transition: opacity 0.25s linear;
const WrapperEl = styled.div`
  // ...
  // width: calc(100% - (${pageMargin} * 2));
  width: 100%;
  transition: ${props => (props.transition ? "opacity 0.25s linear" : "unset")};
  opacity: ${props => (props.isProject ? "0" : "auto")};
  position: absolute;
  display: flex;
  flex-flow: column;
  @media ${breakpoint.xs} {
    padding-bottom: calc(${menuHeights.xs} + (${pageMargin} * 2));
  }
  @media ${breakpoint.s} {
    padding-bottom: calc(${menuHeights.s} + (${pageMargin} * 2));
  }
  @media ${breakpoint.m} {
    padding-bottom: calc(${menuHeights.m} + (${pageMargin} * 2));
  }
  @media ${breakpoint.l} {
    padding-bottom: calc(${menuHeights.l} + (${pageMargin} * 2));
  }
  @media ${breakpoint.xl} {
    padding-bottom: calc(${menuHeights.xl} + (${pageMargin} * 2));
  }
  @media ${breakpoint.xxl} {
    padding-bottom: calc(${menuHeights.xxl} + (${pageMargin} * 2));
  }
`;

class Wrapper extends Component {
  constructor(props) {
    super(props);
    this.state = {
      transition: false
    }
  }

  render() {
    const transition = this.props.location.pathname.includes('/photography')
    return (
      <WrapperEl transition={transition} isProject={this.props.isProject} id="wrapper">
        {this.props.children}
      </WrapperEl>
    )
  }
}

export default Wrapper