import React, { Component } from "react";
import styled, { keyframes } from "styled-components";
import LazyLoad from 'react-lazyload';
import { CSSTransition } from 'react-transition-group';
import { typeSmall, typeGeneral, typeBody, typeBodyTitle, typeMedium, typeLarge, typeCaptions } from "../../styles/typography";
import { breakpoint, menuHeights, pageMargin, fadeIn } from "../../styles/utils";

const StudioFigureEl = styled.div`
  width: 100%;
  opacity: 0;
  transition: opacity .2s linear;
  display: flex;
  flex-flow: column;
  align-items: center;
  &.lazy-enter-done {
    opacity: 1;
  }
  &.lazy-appear-done {
    opacity: 1;
  }
`

const StudioFigureInner = styled.figure`
  width: ${props => (!props.isPortrait ? `100%` : props.isSingle ? `50%` : `60%`)};
  display: flex;
  justify-content: center;
  flex-flow: column;
  & img {
    width: 100%;
    margin-bottom: calc(${pageMargin} / 2);
  }
  & figcaption {
    ${typeCaptions};
  }
  @media ${breakpoint.s} {
    width: ${props => (!props.isPortrait ? `100%` : props.isSingle ? `80%` : `60%`)};
  }
  @media ${breakpoint.xs} {
    width: 100%;
  }
`

const StudioCaption = styled.figcaption`
  ${typeCaptions};
`

const StudioFigure = (props) => {
  const ratio = Math.round(props.ratio * 100) / 100
  const height = parseInt(props.width / props.ratio)
  const isPortrait = props.ratio < 1 ? true : false
  const isSingle = props.isSingle ? true : false

  return (
    <React.Fragment>
    <LazyLoad throtle={100} height={height}>
      {/* add key? */}
      <CSSTransition
        classNames="lazy"
        in={true}
        appear={true}
        timeout={{ enter: 250, exit: 250 }}
      >
        <StudioFigureEl>
          <StudioFigureInner isSingle={isSingle} isPortrait={isPortrait}>
            <img src={props.src} />
            <StudioCaption>{props.caption}</StudioCaption>
          </StudioFigureInner>
        </StudioFigureEl>
        </CSSTransition>
      </LazyLoad>
    </React.Fragment>
  );
}

export default StudioFigure