import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { Route, Switch } from "react-router-dom";
import ProjectContainer from '../containers/ProjectContainer'
import Wrapper from '../components/Wrapper'
import Thumbnail from '../components/Thumbnail'
import styled from "styled-components";
import qs from 'qs'
import { pageMargin, breakpoint } from '../styles/utils'
import { typeGeneral } from '../styles/typography'

class IndexView extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loaded: false
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.data.length !== this.props.data.length && !this.state.loaded) {
      // preload project thumbnails
      const projects = this.props.data.projects.entries 
      projects.map((project, key) => {
        let img = new Image()
        img.src = project.thumbnail.thumbnails.m
        img.onload = function () {
          // console.log('loaded thumb img')
        }
      })
      this.setState({
        loaded: true
      })
    }
  }

  render() {
    return (
      <React.Fragment>
        <Wrapper location={this.props.location} isProject={false} id='index'> 
        </Wrapper>
      </React.Fragment>
    )
  }

}

export default IndexView