import React, { Component } from 'react';
import ProjectView from '../views/ProjectView';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actions from "../state/actions/actions";

const mapStateToProps = state => {
  return {
    data: state.data,
    filter: state.filter,
    mobile: state.mobile,
    isProject: state.isProject,
    isStudio: state.isStudio
  };
}

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(actions, dispatch)
  }
}

class ProjectContainer extends Component {
  constructor(props) {
    super(props)
  }

  componentDidMount() {
    this.props.actions.setProject()
  }

  render() {
    var projects = this.props.data.projects.entries
    var projectSlug = this.props.match.params.title
    
    // lookup + return project
    var projectData = projects.filter(function(project, i) {
      return project.slug == projectSlug
    })

    // pass image to land on if entered via thumb or not
    var selectedImg = this.props.location.index ? this.props.location.index : 0

    return (
      <ProjectView 
        {...this.props}
        index={projects}
        slug={projectSlug} 
        data={projectData[0]} 
        imageIndex={selectedImg} 
      />
    )
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProjectContainer)