import React, { Component } from "react";
import styled, { keyframes } from "styled-components";
import { typeSmall, typeGeneral, typeBody, typeBodyTitle, typeMedium, typeLarge, typeCaptions } from "../../styles/typography";
import { breakpoint, menuHeights, pageMargin, fadeIn, grey } from "../../styles/utils";
import Markup from "interweave";
import StudioFigure from './StudioFigure'

const TextImageEl = styled.div`
  grid-column-start: 1;
  grid-column-end: 7;
  display: flex;
  margin-bottom: ${props => (props.mobile ? `4rem` : `6rem`)};
  align-items: center;
  flex-flow: ${props => (props.mobile ? `column` : `auto`)};
`;

const TextImageColumn = styled.div`
  width: ${props => (props.mobile ? `100%` : `50%`)};
  display: flex;
  & .lazyload-wrapper {
    width: 100%;
  }
  &:first-child {
    margin-right: ${props => (props.mobile ? `0` : `calc(${pageMargin} / 2)`)};
    margin-bottom: ${props => (props.mobile ? `4rem` : `0`)};
  }
  &:last-child {
    margin-left: ${props => (props.mobile ? `0` : `calc(${pageMargin} / 2)`)};
    margin-bottom: 0;
  }
`;

const TextImageParagraph = styled.div`
  width: 100%;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
`;

const TextImageParagraphInner = styled.div`
  width: ${props => (props.mobile ? `100%` : `75%`)};
  @media ${breakpoint.s} {
    width: 100%;
  }
  & h1 {
    ${typeBodyTitle};
    text-align: center;
    margin-bottom: 3rem;
  }
  & p {
    ${typeBody};
    & a {
      color: ${grey};
      text-decoration: none;
      transition: color 0.25s ease;
      @media (hover: hover) {
        &:hover {
          color: black;
        }
      }
    }
  }
`;

const TextImage = (props) => {
  let heading = props.data.heading.length > 0 ? <h1>{props.data.heading}</h1> : false
  let caption = props.data.image.caption.length > 0 ? <figcaption><Markup content={props.data.image.caption} /></figcaption> : false
  let imgWidth = props.mobile ? window.innerWidth : props.data.image.ratio < 1 ? ((window.innerWidth / 2) * .6) : (window.innerWidth / 2)

  return (
    <TextImageEl mobile={props.mobile}>
      <TextImageColumn mobile={props.mobile}>
        <TextImageParagraph>
          <TextImageParagraphInner mobile={props.mobile}>
            {heading}
            <Markup content={props.data.text} />
          </TextImageParagraphInner>
        </TextImageParagraph>
      </TextImageColumn>
      <TextImageColumn mobile={props.mobile}>
        <StudioFigure isSingle={false} width={imgWidth} ratio={props.data.image.ratio} src={props.data.image.thumbnails.l} caption={caption} />
      </TextImageColumn>
    </TextImageEl>
  );
}

export default TextImage