import styled, { keyframes, css } from "styled-components";
import { breakpoint, menuHeights, pageMargin } from "../styles/utils";
import { typeGeneral, typeCaptions } from "../styles/typography";

const leftCursorStyle = `w-resize;`
const rightCursorStyle = `e-resize;`

export const Slide = styled.div`
  width: 100%;
  height: 100%;
  background: white;
  cursor: ${props => (!props.cursorState ? leftCursorStyle : rightCursorStyle)};
`;

var returnWh = () => {
  return window.innerHeight + 'px'
}

const portraitHeight = css`
  height: calc(100vh - ((${pageMargin} * 3) + (0.9em)));
  @media ${breakpoint.xs} {
    height: calc(${returnWh} - ((${pageMargin} * 3) + (0.9em)));
  }
  @media ${breakpoint.landscape} {
    height: calc(${returnWh} - ((${pageMargin} * 3) + (0.9em)));
  }
`;

const landscapeHeight = css`
  height: auto;
`;

const portraitMargin = css`
  margin-top: calc((100vh - ((${pageMargin} * 3) + (0.9em))) * 0.075);
  @media ${breakpoint.xs} {
    margin-top: calc((${returnWh} - ((${pageMargin} * 3) + (0.9em))) * 0.15);
  }
  @media ${breakpoint.landscape} {
    margin-top: calc((${returnWh} - ((${pageMargin} * 3) + (0.9em))) * 0.15);
  }
`;

const landscapeMargin = css`
  margin-top: 0;
`;

const portraitMaxHeight = css`
  max-height: 85%;
  @media ${breakpoint.xs} {
    max-height: 75%;
  }
  @media ${breakpoint.landscape} {
    max-height: 75%;
  }
`;

const landscapeMaxHeight = css`
  max-height: calc((100vh - ((${pageMargin} * 3) + (0.9em))) * 0.85);
  @media ${breakpoint.xs} {
    max-height: calc((${returnWh} - ((${pageMargin} * 3) + (0.9em))) * 0.75);
  }
  @media ${breakpoint.landscape} {
    max-height: calc((${returnWh} - ((${pageMargin} * 3) + (0.9em))) * 0.75);
  }
`;

const portraitTop = css`
  top: 0;
`;

const landscapeTop = css`
  top: calc((100vh - ((${pageMargin} * 3) + (0.9em))) * 0.5);
  @media ${breakpoint.xs} {
    top: calc((${returnWh} - ((${pageMargin} * 3) + (0.9em))) * 0.5);
  }
  @media ${breakpoint.landscape} {
    top: calc((${returnWh} - ((${pageMargin} * 3) + (0.9em))) * 0.5);
  }
`;

const portraitTransform = css`
  transform: translateY(0);
`;

// .5rem needs to account for caption font size
const landscapeTransformCaption = css`
  transform: translateY(calc(-50% + 0.5rem));
`;

const landscapeTransformNoCaption = css`
  transform: translateY(-50%);
`;

export const SlideInner = styled.div`
  position: relative;
  width: 100%;
  ${props => (props.ratio < 1 ? portraitHeight : landscapeHeight)};
  ${props => (props.ratio < 1 ? portraitTop : landscapeTop)};
  ${props =>
    props.ratio < 1
      ? portraitTransform
      : props.hasCaption
        ? landscapeTransformCaption
        : landscapeTransformNoCaption};
  transition: ${props => (props.transition ? "opacity 0.5s linear" : "none")};
  opacity: ${props => (props.visible ? "1" : "0")};
  background: white;
  & img {
    position: relative;
    object-fit: ${props => (props.ratio === 1 ? "contain" : "")};
    object-fit: contain;
    vertical-align: middle;
    height: ${props =>
    props.ratio === 1 ? "auto" : props.ratio < 1 ? "85%" : "auto"};
    ${props => (props.ratio < 1 ? portraitMargin : landscapeMargin)};
    ${props => (props.ratio < 1 ? portraitMaxHeight : landscapeMaxHeight)};
    max-width: calc(100% - (${pageMargin} * 2));
    left: 50%;
    transform: translateX(-50%);
    @media ${breakpoint.xs} {
      height: ${props => (props.ratio < 1 ? "70%" : "auto")};
    }
    @media ${breakpoint.landscape} {
      height: ${props => (props.ratio < 1 ? "70%" : "auto")};
    }
  }
  & video {
    position: relative;
    object-fit: ${props => (props.ratio === 1 ? "contain" : "")};
    object-fit: contain;
    vertical-align: middle;
    height: ${props =>
    props.ratio === 1 ? "auto" : props.ratio < 1 ? "85%" : "auto"};
    ${props => (props.ratio < 1 ? portraitMargin : landscapeMargin)};
    ${props => (props.ratio < 1 ? portraitMaxHeight : landscapeMaxHeight)};
    max-width: calc(100% - (${pageMargin} * 2));
    left: 50%;
    transform: translateX(-50%);
    @media ${breakpoint.xs} {
      height: ${props => (props.ratio < 1 ? "70%" : "auto")};
    }
    @media ${breakpoint.landscape} {
      height: ${props => (props.ratio < 1 ? "70%" : "auto")};
    }
  }
`;

export const CaptionWrap = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 0.5rem;
  & figcaption {
    width: 65%;
    text-align: center;
    ${typeCaptions};
    @media ${breakpoint.xs} {
      width: 100%;
    }
    & p {
      margin-bottom: 0;
    }
  }
  @media ${breakpoint.xs} {
    width: 100%;
    padding: 0 ${pageMargin} 0 ${pageMargin};
    position: fixed;
    bottom: 0;
    margin-bottom: 20px;
  }
  @media ${breakpoint.landscape} {
    position: relative;
    padding-top: 0.5rem;
    margin-bottom: 0;
  }
`;

export const CaptionWrapLandscapeXS = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 0.5rem;
  width: calc(100% - (${pageMargin} * 2));
  padding: 0 ${pageMargin} 0 ${pageMargin};
  position: fixed;
  bottom: 0;
  margin-bottom: 30px;
  transition: opacity 0.5s linear;
  opacity: ${props => (props.visible ? "1" : "0")};
  & figcaption {
    width: 100%;
    text-align: center;
    ${typeCaptions};
    & p {
      margin-bottom: 0;
    }
  }
`;