import React, { Component } from 'react';
import { Link, Route, Switch } from "react-router-dom";
import { TermsWrap } from "../styles/about";

class TermsView extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    return (
      <TermsWrap>
        <embed src={process.env.PUBLIC_URL + "/terms.pdf"} ></embed>
      </TermsWrap>
    )
  }

}

export default TermsView