import React, { Component } from "react";
import styled, { keyframes } from "styled-components";
import { typeGeneral, typeMedium } from "../styles/typography";
import { breakpoint, menuHeights, pageMargin } from "../styles/utils";
import { Link } from "react-router-dom";
import LazyLoad from 'react-lazyload';
import { CSSTransition } from 'react-transition-group';
import { bpSizes } from '../styles/utils'

// height is based off design (ratio is 0.8)
// = size of div (container which is 100vw - 20px for margin) multiplied by 1.2
const Thumb = styled.div`
  display: flex;
  width: 100%;
  position: relative;
  overflow: hidden;
  pointer-events: ${props => (props.pointerEvents ? "all" : "none")};
  & .lazyload-wrapper {
    height: 100%;
  }
  & a {
    opacity: 0;
    transition: opacity .2s linear;
    pointer-events: none;
    &.lazy-enter-done {
      pointer-events: ${props => (props.pointerEvents ? "all" : "none")};
      opacity: 1;
    }
    &.lazy-appear-done {
      pointer-events: ${props => (props.pointerEvents ? "all" : "none")};
      opacity: 1;
    }
    text-decoration: none;
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: column;
  }
`;

const ThumbImageWrapper = styled.div`
  width: 100%;
  overflow: hidden;
  @media ${breakpoint.xs} {
    margin-bottom: 9px;
    height: calc(((100vw - (${pageMargin}) * 2) * .5) * 1.25);
  }
  @media ${breakpoint.s} {
    height: calc(((100vw - (${pageMargin}) * 2) * .33) * 1.25);
  }
  @media ${breakpoint.m} {
    height: calc(((100vw - (${pageMargin}) * 2) * .167) * 1.25);
  }
  @media ${breakpoint.l} {
    height: calc(((100vw - (${pageMargin}) * 2) * .167) * 1.25);
  }
  @media ${breakpoint.xl} {
    height: calc(((100vw - (${pageMargin}) * 2) * .167) * 1.25);
  }
  @media ${breakpoint.xxl} {
    height: calc(((100vw - (${pageMargin}) * 2) * .167) * 1.25);
  }
  & img {
    min-height: 100%;
    object-fit: cover;
    filter: ${props => (props.blurImage ? "blur(10px)" : "blur(0)")};
    transform: ${props => (props.blurImage ? "scale(1.03)" : "scale(1)")};
  }
`


const ThumbOverlay = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  background: black;
  z-index 2;
  opacity: ${props => props.showOverlay ? '.2' : '0'};
`;

const ThumbText = styled.div`
  position: absolute;
  bottom: 0;
  transform: ${props => (props.showCaption ? "translateY(0)" : "translateY(50%)")};
  opacity: ${props => (props.showCaption ? "1" : "0")};
  ${typeGeneral};
  color: white;
  padding: 0 .5rem .75rem .5rem;
  transition: transform .25s ease, opacity .25s ease;
  z-index: 3;
  text-decoration: none;
  @media ${breakpoint.xs} {
    position: relative;
    transform: translateY(0);
    opacity: 1;
    color: black;
    padding: 0;
    ${typeMedium};
  }
`


class Thumbnail extends Component {
  constructor(props) {
    super(props);
    const self = this 
    this.state = {
      loaded: false,
      src: '',
      hovered: false,
      pointerEvents: false
    }
  }

  componentDidMount() {
    this.setState({
      pointerEvents: this.props.menuAnimating
    })
  }

  handleMouseOver() {
    if (!this.props.mobile) {
      !this.state.hovered ? this.setState({ hovered: true }) : false
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.menuAnimating !== this.props.menuAnimating) {
      this.setState({
        pointerEvents: this.props.menuAnimating
      })
    }
  }

  handleMouseLeave() {
    if (!this.props.mobile) {
      this.state.hovered ? this.setState({ hovered: false }) : false
    }
  }

  returnThumbHeight() {
    let ww = window.innerWidth
    if (ww <= bpSizes.a) {
      return ((ww * .5) * 1.25)
    }
    else if (ww > bpSizes.a && ww <= bpSizes.b) {
      return ((ww * .33) * 1.25)
    }
    else if (ww > bpSizes.b && ww <= bpSizes.c) {
      return ((ww * .167) * 1.25)
    }
    else if (ww > bpSizes.c && ww <= bpSizes.d) {
      return ((ww * .167) * 1.25)
    }
    else if (ww > bpSizes.d && ww <= bpSizes.e) {
      return ((ww * .167) * 1.25)
    }
    else if (ww > bpSizes.e) {
      return ((ww * .167) * 1.25)
    }
    else {
      return ((ww * .167) * 1.25)
    }
  }


  render() {
    let data = this.props.data 
    let src = this.props.data.thumbnail.thumbnails.m
    let subtitle = this.props.data.subtitle.length > 0 ? <div>{this.props.data.subtitle}</div> : false
    return (
      <Thumb pointerEvents={!this.state.pointerEvents} onMouseOver={this.handleMouseOver.bind(this)} onMouseLeave={this.handleMouseLeave.bind(this)}>
        <LazyLoad throtle={100} height={this.returnThumbHeight()}>
          <CSSTransition
            classNames="lazy"
            in={true}
            appear={true}
            timeout={{ enter: 250, exit: 250 }}
          >
          <Link to={this.props.link}>
            <ThumbImageWrapper blurImage={this.state.hovered} >
              <ThumbOverlay showOverlay={this.state.hovered} />
              <img src={src} />
            </ThumbImageWrapper>
            <ThumbText showCaption={this.state.hovered}>
              <div>{this.props.data.title}</div>
              {subtitle}
            </ThumbText>
          </Link> 
          </CSSTransition>
        </LazyLoad>
      </Thumb>
    );
  }
}
    

export default Thumbnail;
