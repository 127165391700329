import styled, { keyframes } from "styled-components";
import { pageMargin, breakpoint, menuHeights, grey } from "./utils";
import { typeGeneral, typeCaption, typeLarge } from "./typography";

const fadeIn = () => {
  return keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;
};

export const MenuWrap = styled.div`
  ${typeGeneral};
  width: calc(100% - (${pageMargin} * 2));
  position: fixed;
  bottom: 0;
  margin: 0 0 ${pageMargin} 0;
  z-index: 13;
  transition: height 0.75s ease;
  background: white;
  border: solid black 1px;
  border-top: ${props => (props.isProject ? "none" : "solid black 1px")};
  border-radius: 8px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  display: flex;
  border-color: ${props => (props.isProject ? "white" : "black")};
  pointer-events: ${props => (props.isProject ? "none" : "all")};
  align-items: center;
  justify-content: center;
  overflow: hidden;
  &::-webkit-scrollbar {
    display: none;
  }
  @media ${breakpoint.xs} {
    overflow: scroll;
      height: ${props =>
      !props.isRoot
        ? !props.hideMenu
          ? `calc(100% - (${pageMargin} * 2))`
          : `${menuHeights.xs}`
        : `calc(100% - (${pageMargin} * 2))`};
      display: inline-block;
    }
    @media ${breakpoint.s} {
      height: ${props =>
      !props.isRoot
        ? `${menuHeights.s}`
        : `calc(100% - (${pageMargin} * 2))`};
    }
    @media ${breakpoint.m} {
      height: ${props =>
      !props.isRoot
        ? `${menuHeights.m}`
        : `calc(100% - (${pageMargin} * 2))`};
    }
    @media ${breakpoint.l} {
      height: ${props =>
      !props.isRoot
        ? `${menuHeights.l}`
        : `calc(100% - (${pageMargin} * 2))`};
    }
    @media ${breakpoint.xl} {
      height: ${props =>
      !props.isRoot
        ? `${menuHeights.xl}`
        : `calc(100% - (${pageMargin} * 2))`};
    }
    @media ${breakpoint.xxl} {
      height: ${props =>
      !props.isRoot
        ? `${menuHeights.xxl}`
        : `calc(100% - (${pageMargin} * 2))`};
    }
`

// <ul> which wraps lis
export const MenuWrapInner = styled.ul`
  padding: 0 calc(${pageMargin} * 2) 0 calc(${pageMargin} * 2);      
  justify-content: space-between;
  transition: opacity .5s linear;
  opacity: ${props => (props.isProject || props.isIndex ? "0" : props.animating ? "0" : "1")};
  width: ${props => (!props.isIndex ? "100%" : "auto")};
  margin-bottom: ${props => (props.isProject ? "-1px" : "0")};
  display: flex;
  @media ${breakpoint.xs} {
    opacity: ${props => (props.isProject || props.isIndex || !props.showMobileMenu ? "0" : props.animating ? "0" : "1")};
    padding: 0;
    flex-flow: column;
  }
  & li {
    & a {
      text-decoration: none;
      transition: color 0.25s ease;
      color: black;
      &.active {
        color: ${grey};
      }
      &:not(:last-child) {
        margin-right: .5em;
      }
      @media (hover: hover) {
        &:hover {
          color: ${grey};
        }
      }
    }
    @media ${breakpoint.xs} {
      background: white;
      text-align: center;
      border: solid 1px black;
      border-top: none;
      border-left: none;
      border-right: none;
      &:not(.switch) {
        padding: 1em 0 1em 0;
      }
    }
  }
`

export const MobileMenuWrap = styled.div`
  padding-bottom: calc(${menuHeights.xs} - 2px);
  opacity: ${props => (props.isProject || props.isAnimating ? "0" : "1")};
  pointer-events: ${props => (props.isProject ? "none" : "all")};
`

export const MobileMenuSwitch = styled.li`
  display: flex;
`

export const MobileMenuSwitchItem = styled.div`
  width: 50%;
  padding: 1em 0 1em 0;
  cursor: pointer;
  color: ${props => (props.isActive ? grey : 'black')};
  &:first-child {
    border: solid black 1px;
    border-left: 0;
    border-top: 0;
    border-bottom: 0;
  }
`

export const MobileMenuBtn = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  bottom: ${pageMargin};
  width: calc(100% - (${pageMargin} * 2));
  border: solid 1px black;
  border-radius: 8px;
  margin-left: -1px;
  height: ${menuHeights.xs};
  background: white;
  z-index: 3;
`

export const MenuMenuEl = styled.div`
  padding-bottom: calc(${menuHeights.xs} - 2px);
`

export const MenuStudioFilter = styled.span`
  transition: color 0.25s ease;
  color: ${props => (props.isActive ? grey : 'black')};
  @media (hover: hover) {
    &:hover {
      color: ${grey};
      cursor: pointer;
    }
  }
  &:not(:last-child) {
    &:after {
      content: ", ";
    }
  }
  & a {
    color: black;
    text-decoration: none;
    @media (hover: hover) {
      &:hover {
        color: ${grey};
        cursor: pointer;
      }
    }
  }
  @media ${breakpoint.xs} {
    display: block;
    background: white;
    padding: 1em 0 1em 0;
    text-align: center;
    border: solid 1px black;
    border-top: none;
    border-left: none;
    border-right: none;
    &:not(:last-child) {
      &:after {
        content: unset;
      }
    }
  }
`

export const MenuColumn = styled.div`
  width: 50%;
  // display: flex;
  height: 100%;
  transition: opacity .25s linear;
  display: ${props => (props.animating ? "none" : "flex")}; 
  opacity: ${props => (props.animating ? "0" : "1")}; 
  pointer-events: ${props => (props.animating ? "none" : "all")}; 
  &:first-child {
    border-bottom: 0;
    border-left: 0;
    border-top: 0;
    border-right: solid black 1px;
    @media ${breakpoint.xs} {
      border-right: 0;
      border-bottom: solid black 1px;
    }
  }
  & a {
    width: 100%;
    height: 100%;
    display: flex;
    position: relative;
    cursor: pointer;
  }
  @media ${breakpoint.xs} {
    width: 100%;
    height: 50%;
    opacity: 1;
  }
`;

export const MenuColumnLayer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background: url(${props => props.background}) center center;
  background-size: cover;
  opacity: ${props => (props.showLayer ? "1" : "0")}; 
  transition: opacity .15s linear;
  @media ${breakpoint.xs} {
    opacity: 1;
  }
  &:first-child {
    background: none;
    @media ${breakpoint.xs} {
      background: url(${props => props.background}) center center;
      background-size: cover;
    }
  }
  &:last-child {
    @media ${breakpoint.xs} {
      display: none;
    }
  }
  z-index: ${props => props.zIndex};
`;

export const MenuColumnText = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  display: flex;
  flex-flow: column;
  text-align: center;
  color: black;
  ${typeLarge};
  transition: margin .3s ease; 
  &.hidden {
    margin-top: .5rem;
    @media ${breakpoint.xs} {
      margin-top: 0;
    }
  }
`;

export const MenuColumnTextLine = styled.div`
  &.white {
    text-shadow: 0 2px 4px #00000033;
    color: white;
  }
  &.black {
    color: black;
    @media ${breakpoint.xs} {
      text-shadow: 0 2px 4px #00000033;
      color: white;
    }
  }
`;

export const Psuedo = styled.div`
  width: calc(100% - (${pageMargin} * 2));
  position: fixed;
  bottom: 0;
  height: calc(${pageMargin} * 2);
  background: white;
  z-index: 2;
`;