import React, { Component } from "react";
import { withRouter } from "react-router";
import { lockScroll, unlockScroll, setScroll, resetScroll } from '../utils/scroll'
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as actions from "../state/actions/actions";

const mapStateToProps = state => {
  return {
    data: state.data,
    menuAnimating: state.menuAnimating,
    isProject: state.isProject,
    scrollPos: state.scrollPos,
    mobile: state.mobile
  }
}

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(actions, dispatch)
  }
}

class ScrollWatch extends Component {
  constructor(props) {
    super(props);
    // 250 is time of transition, + extra to account for delay
    this.speed = 500
    this.state = {
      loaded: false 
    }
  }

  componentDidMount() {
  }

  componentDidUpdate(prevProps) {
    const self = this 

    // received data
    if (this.props.data !== prevProps.data) {
      if (this.props.location.hash.length && !this.state.loaded) {
      const self = this 
        setTimeout(() => {
          let hashId = self.props.location.hash.substring(1)
          let anchorDiv = document.getElementById(hashId)
          // 50 is optical -- menu + padding
          let dist = (window.pageYOffset + anchorDiv.getBoundingClientRect().top) - 50
          self.props.actions.setScrollPos(dist)
          setScroll(dist)
          self.setState({
            loaded: true 
          })
        }, 1)
      }
    }


    if (this.props.location !== prevProps.location) {

      if (this.props.mobile) {
        unlockScroll()
        this.props.actions.hideMenu()
        if (this.props.location.pathname !== '/') {
          this.props.actions.setMenuAnimating()
        }
      }
      
      // when going from any path > route, set global state as animating
      // (this gets unset with transitionend callback)
      if (this.props.location.pathname === '/' && !this.props.mobile) {
        this.props.actions.setMenuAnimating()
      }

      // added
      if (prevProps.location.pathname === '/autonomous') {
        this.props.actions.setScrollPos(window.pageYOffset) 
      }
      if (this.props.location.pathname === '/autonomous') {
        this.props.actions.setStudio()
        if (prevProps.location.pathname.includes('/photography/')) {
          setScroll(this.props.scrollPos)
        }
        else {
          setTimeout(() => {
            resetScroll()
          }, self.speed)
        }
      }
      // if going to /projects (not /projects/:id), set studio globally
      if (this.props.location.pathname === '/photography') {
        this.props.actions.unsetStudio()
      }
      // if going from project/:id > anywhere else
      if (!this.props.location.pathname.includes('/photography/') && this.props.isProject) {
        // if studio, wait to unset project to allow <Wrapper> to transition in again
        if (this.props.location.pathname === '/autonomous') {
          setTimeout(() => {
            self.props.actions.unsetProject()
          }, 750)
        }
        // if not studio just unset project
        else {
          this.props.actions.unsetProject()
        }
      }


      // scroll reset

      if (prevProps.location.pathname === '/about' && this.props.location.pathname === '/photography') {
        setTimeout(() => {
          resetScroll()
        }, self.speed)
      }
      // if projects -> about
      else if (prevProps.location.pathname === '/photography' && this.props.location.pathname === '/about') {
        setTimeout(() => {
          resetScroll()
        }, self.speed)
      }
      // if studio -> projects
      else if (prevProps.location.pathname === '/autonomous' && this.props.location.pathname === '/photography') {
        setTimeout(() => {
          resetScroll()
        }, self.speed)
      }
      // if projects -> studio
      else if (prevProps.location.pathname === '/photography' && this.props.location.pathname === '/autonomous') {
        setTimeout(() => {
          resetScroll()
        }, self.speed)
      }
      // if studio -> about
      else if (prevProps.location.pathname === '/autonomous' && this.props.location.pathname === '/about') {
        setTimeout(() => {
          resetScroll()
        }, self.speed)
      }
      // if about -> studio
      else if (prevProps.location.pathname === '/about' && this.props.location.pathname === '/autonomous') {
        setTimeout(() => {
          resetScroll()
        }, self.speed)
      }
      // if about -> privacy policy
      else if (prevProps.location.pathname === '/about' && this.props.location.pathname === '/privacy-policy') {
        setTimeout(() => {
          resetScroll()
        }, self.speed)
      }
      // if privacy policy -> about
      else if (prevProps.location.pathname === '/privacy-policy' && this.props.location.pathname === '/about') {
        setTimeout(() => {
          resetScroll()
        }, self.speed)
      }
    }
  }

  render() {
    return this.props.children
  }
}

export default withRouter(connect(mapStateToProps,mapDispatchToProps)(ScrollWatch))