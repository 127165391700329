import React, { Component } from 'react';
import Wrapper from "../components/Wrapper";
import Menu from "../components/Menu";
import Markup from "interweave";
import Loader from '../components/Loader'
import { Link } from 'react-router-dom'

import {
  PageTitle,
  TextBlock,
  Grid,
  Policy,
  Col
} from "../styles/about";


const PolicyView = ({ location, ...props }) => {
  if (props.data.length === 0) {
    return <Loader hideLoader={false} />
  }
  else {
    const data = props.data.about
    return (
      <Wrapper location={location} isIndex={false} isProject={false}>
        <PageTitle>
          Privacy Policy
          <Link to = '/about'><img src={process.env.PUBLIC_URL + "/close.svg"} /></Link>
        </PageTitle>
        <Grid>
          <Policy>
            <Markup content={data.policy} />
          </Policy>
        </Grid>
      </Wrapper>
    )
  }

}

export default PolicyView