import React, { Component } from "react";
import styled, { keyframes } from "styled-components";
import { breakpoint, menuHeights, pageMargin, fadeIn } from "../styles/utils";
import {
  loaderOne,
  loaderTwo,
  loaderThree,
  loaderFour,
  loaderFive,
  loaderSix,
  loaderSeven,
  loaderEight,
  loaderNine,
  loaderTen,
  loaderEleven,
  loaderTwelve
} from "../styles/loader";

const LoaderText = styled.h1`
  opacity: ${props => (props.hideLoader ? `0` : `1`)};
  font-size: 2.025em;
  letter-spacing: -0.005em;
  z-index: 1000;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  width: calc(100% - (${pageMargin} * 2));
  text-align: center;
  transition: opacity 0.5s linear;
  & #one {
    animation-direction: alternate;
    animation: ${loaderTwelve} 1.25s ease-out infinite !important;
  }
  & #two {
    animation-direction: alternate;
    animation: ${loaderEleven} 1.25s ease-out infinite !important;
  }
  & #three {
    animation-direction: alternate;
    animation: ${loaderTen} 1.25s ease-out infinite !important;
  }
  & #four {
    animation-direction: alternate;
    animation: ${loaderNine} 1.25s ease-out infinite !important;
  }
  & #five {
    animation-direction: alternate;
    animation: ${loaderEight} 1.25s ease-out infinite !important;
  }
  & #six {
    animation-direction: alternate;
    animation: ${loaderSeven} 1.25s ease-out infinite !important;
  }
  & #seven {
    animation-direction: alternate;
    animation: ${loaderSix} 1.25s ease-out infinite !important;
  }
  & #eight {
    animation-direction: alternate;
    animation: ${loaderFive} 1.25s ease-out infinite !important;
  }
  & #nine {
    animation-direction: alternate;
    animation: ${loaderFour} 1.25s ease-out infinite !important;
  }
  & #ten {
    animation-direction: alternate;
    animation: ${loaderThree} 1.25s ease-out infinite !important;
  }
  & #eleven {
    animation-direction: alternate;
    animation: ${loaderTwo} 1.25s ease-out infinite !important;
  }
  & #twelve {
    animation-direction: alternate;
    animation: ${loaderOne} 1.25s ease-out infinite !important;
  }
`;


class Loader extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    return (
      <LoaderText hideLoader={this.props.hideLoader}>
        <span id="one">M</span>
        <span id="two">a</span>
        <span id="three">f</span>
        <span id="four">a</span>
        <span id="five">l</span>
        <span id="six">d</span>
        <span id="seven">a</span>
        &nbsp;
        <span id="eight">R</span>
        <span id="nine">a</span>
        <span id="ten">k</span>
        <span id="eleven">o</span>
        <span id="twelve">š</span>
      </LoaderText>
    )

  }
}

export default Loader;
