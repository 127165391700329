import {
  SET_DATA,
  SET_STUDIO_FILTER,
  SET_FILTER,
  SET_PROJECT,
  UNSET_PROJECT,
  SET_DEVICE_WIDTH,
  SET_THUMB_COUNT,
  LOAD_THUMB,
  SHOW_THUMB,
  HIDE_LOADER,
  HIDE_MENU,
  SHOW_MENU,
  SET_SCROLL_POS,
  SET_STUDIO,
  UNSET_STUDIO,
  SET_MENU_ANIMATING,
  UNSET_MENU_ANIMATING,
  SHOW_MOBILE_MENU,
  HIDE_MOBILE_MENU
} from "../actions/actions";

const rootReducer = (state = {}, action) => {
  switch (action.type) {
    case SET_DATA:
      return Object.assign({}, state, {
        data: action.data
      })
    case SET_DEVICE_WIDTH:
      return Object.assign({}, state, {
        mobile: action.x <= 480 ? true : false,
        breakpointXL: action.x < 1601 && action.x >= 1441 ? true : false,
        breakpointXXL: action.x > 1600 ? true : false
      });
    case SET_PROJECT:
      return Object.assign({}, state, {
        isProject: true
      })
    case UNSET_PROJECT:
      return Object.assign({}, state, {
        isProject: false
      })
    case SET_STUDIO_FILTER:
      return Object.assign({}, state, {
        studioFilter: action.query
      })
    case SET_FILTER:
      return Object.assign({}, state, {
        filter: action.query
      })
    case SET_THUMB_COUNT:
      return Object.assign({}, state, {
        thumbCount: action.n
      })
    case LOAD_THUMB:
      return Object.assign({}, state, {
        thumbLoadCount: state.thumbLoadCount += 1,
        loadedThumbs: state.thumbLoadCount === state.thumbCount ? true : false
      })
    case SHOW_THUMB:
      return Object.assign({}, state, {
        thumbShowCount: state.thumbShowCount += 1,
        showThumbs: state.thumbShowCount === state.thumbCount ? true : false
      })
    case HIDE_LOADER:
      return Object.assign({}, state, {
        loaderHidden: true
      })
    case HIDE_MENU:
      return Object.assign({}, state, {
        hideMenu: true
      })
    case SHOW_MENU:
      return Object.assign({}, state, {
        hideMenu: false
      })
    case SET_SCROLL_POS:
      return Object.assign({}, state, {
        scrollPos: action.y
      })
    case SET_STUDIO:
      return Object.assign({}, state, {
        isStudio: true
      })
    case UNSET_STUDIO:
      return Object.assign({}, state, {
        isStudio: false
      })
    case SET_MENU_ANIMATING:
      return Object.assign({}, state, {
        menuAnimating: true
      })
    case UNSET_MENU_ANIMATING:
      return Object.assign({}, state, {
        menuAnimating: false
      })
    case SHOW_MOBILE_MENU:
      return Object.assign({}, state, {
        showMobileMenu: true
      })
    case HIDE_MOBILE_MENU:
      return Object.assign({}, state, {
        showMobileMenu: false
      })
    default:
      return state;
  }
}

export default rootReducer